import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { SingleProductComponent } from './components/single-product/single-product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartComponent } from './components/cart/cart.component';
import { MatToolbarModule, MatSidenavModule, MatListModule, MatButtonModule,
   MatIconModule,MatMenuModule, MatExpansionModule, MatCardModule,MatDialogModule, 
   MatFormFieldModule,MatInputModule, MatTabsModule, MatGridListModule,MatOptionModule, MatSelectModule} from "@angular/material";
import {MatSnackBarModule} from '@angular/material'
import { FlexLayoutModule } from "@angular/flex-layout";
import { ProductsService } from './services/products.service';
import { SelectDeliveryComponent } from './components/select-delivery/select-delivery.component';
import { AddressDailogComponent } from './components/address-dailog/address-dailog.component';
import { LoginComponent } from './components/login/login.component';
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { TimeSlotsComponent } from './components/time-slots/time-slots.component';
import { NgOtpInputModule } from "ng-otp-input";
import { AngularFireModule } from "@angular/fire";
import { environment } from 'src/environments/environment';
import { WindowService } from './services/window.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { GroceryComponent } from './components/grocery/grocery.component';
import { SignupComponent } from './components/signup/signup.component';
import { TermsComponent } from './components/terms/terms.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ProductsComponent } from './components/products/products.component';
import { FooterComponent } from './components/footer/footer.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { TestingComponent } from './components/testing/testing.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { RefundComponent } from './components/refund/refund.component';
import { FrequentlyComponent } from './components/frequently/frequently.component';
import { SellerordersComponent } from './components/sellerorders/sellerorders.component';
import { DealsdialogComponent } from './components/dealsdialog/dealsdialog.component';
import { UpdateproductComponent } from './components/updateproduct/updateproduct.component';
import { UpdateTodaydealComponent } from './components/update-todaydeal/update-todaydeal.component';
import { VmartaddressComponent } from './components/vmartaddress/vmartaddress.component';
import { OrdersComponent } from './components/orders/orders.component';
import { BannerupdateComponent } from './components/bannerupdate/bannerupdate.component';
import { AdvatiseupdateComponent } from './components/advatiseupdate/advatiseupdate.component';
import { CategoryUpdateComponent } from './components/category-update/category-update.component';
import { SubcategoryUpdateComponent } from './components/subcategory-update/subcategory-update.component';





@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    SingleProductComponent,
    CartComponent,
    SelectDeliveryComponent,
    AddressDailogComponent,
    LoginComponent,
    AdminPanelComponent,
    ConfirmDialogComponent,
    TimeSlotsComponent,
    GroceryComponent,
    SignupComponent,
    TermsComponent,
    AboutUsComponent,
    ContactUsComponent,
    ProductsComponent,
    FooterComponent,
    DialogComponent,
    TestingComponent,
    ForgotComponent,
    PrivacypolicyComponent,
    RefundComponent,
    FrequentlyComponent,
    SellerordersComponent,
    DealsdialogComponent,
    UpdateproductComponent,
    UpdateTodaydealComponent,
    VmartaddressComponent,
    OrdersComponent,
    BannerupdateComponent,
    AdvatiseupdateComponent,
    CategoryUpdateComponent,
    SubcategoryUpdateComponent,
   
    
  ],
  entryComponents: [
    AddressDailogComponent,
    ConfirmDialogComponent,
    DialogComponent,
    ForgotComponent,
    UpdateproductComponent,
    UpdateTodaydealComponent,
    BannerupdateComponent,
    AdvatiseupdateComponent,
    CategoryUpdateComponent,
    SubcategoryUpdateComponent

  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatExpansionModule, 
    MatCardModule,
    FlexLayoutModule,
    NgOtpInputModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule
  ],
  providers: [ProductsService,
    WindowService],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
