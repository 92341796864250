import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from "@angular/material";
import { HomeComponent } from "../home/home.component";
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { ProductsService } from '../../services/products.service';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-bannerupdate',
  templateUrl: './bannerupdate.component.html',
  styleUrls: ['./bannerupdate.component.css']
})
export class BannerupdateComponent implements OnInit {
  advcategories: any;
  advitiseForm: FormGroup;
  catAdvatise: string;
  catsubAdvatise: string;
  advProduct: any;
  subCategories: any;
  subcatArray: Array<any> = [];
  user : any;
  url;
  format;
  productImg: any = "";
  constructor(
    private dialogRef: MatDialogRef<HomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private productService: ProductsService,
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.advcategories = this.data.categoryList;
    this.advProduct = this.data.products;
    this.url = "https://liveapi-vmart.softexer.com"+this.advProduct.banerImage;
    this.format = 'image';
    this.user = JSON.parse(localStorage.getItem("user")) || null;
    console.log(this.user);
    if (!this.user && !this.user.PhoneNumber) {
      this.openSnackBar("Please Login..!","");
      this.dialogRef.close(false);
    }    
  }

  UpdateBanner(){
    let phno = this.user.PhoneNumber.substr(3);
    let payLoad = {
      PhoneNumber : phno,
      type:"Banner",
      Id : this.advProduct.banersID
    }
    console.log(payLoad);
    const productDetails = new FormData();
    productDetails.append("image",this.productImg);
    productDetails.append("dashboardInfo", JSON.stringify(payLoad));
    let token = localStorage.getItem('token');
    this.productService.adminUpdateBannerImage(productDetails, token).subscribe((res) => {
      console.log("Res", res)
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.dialogRef.close(true);
      } else {
        this.openSnackBar(res.message, "");
        this.dialogRef.close(false);
      }
    }, (err) => {
      console.log("error", err)
    })
  }
  // Submit Registration Form
  onSubmit() {
    this.user = JSON.parse(localStorage.getItem("user")) || null;
    console.log(this.user);
    if (this.user && this.user.userID) {
      this.openSnackBar("Ok Proceed the inforamtion","");
    } else {
      this.openSnackBar("Please Login..!","");
    }
    if(!this.advitiseForm.valid) {
      alert('Please fill all the required fields to create a super hero!')
      return false;
    } else {
      console.log(this.advitiseForm.value)
    }
  }
  onSelectFile(event) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      
      if(file.type.indexOf('image')> -1){
        this.format = 'image';
      } else if(file.type.indexOf('video')> -1){
        this.format = 'video';
      }
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
        console.log(this.url);
        let imgFile = new File([file], this.url);
        console.log(imgFile)
        this.productImg = file;
      }
    }
  }

  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  closeTab() {
    this.dialogRef.close(false);
  }
  
}
