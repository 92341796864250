import { Component, OnInit,Inject,ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from "@angular/material";
import { HomeComponent } from "../home/home.component";
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { ProductsService } from '../../services/products.service';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-update-todaydeal',
  templateUrl: './update-todaydeal.component.html',
  styleUrls: ['./update-todaydeal.component.css']
})
export class UpdateTodaydealComponent implements OnInit {
  todayProductInfo : any;
  addProductForm: FormGroup;
  fileName: string = "";
  productImg: any = "";
  constructor(
    private dialogRef: MatDialogRef<HomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private productService: ProductsService,
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.todayProductInfo = this.data;
    this.addProductForm = this.fb.group({
      ProductImage: [''],
      ProductName: new FormControl('', [Validators.required]),
      Brand: new FormControl('', [Validators.required]),
      description:  new FormControl('', [Validators.required]),
      quantity: new FormControl('', [Validators.required]),
      MRP_Price: new FormControl('', [Validators.required]),
      VMART_Price:new FormControl('', [Validators.required]),
      Netweight:""
    })
    this.updatePatchValues();
    console.log(this.data);
  }

  updatePatchValues(){
    this.addProductForm.patchValue({
      ProductName : this.data.ProductName,
      Brand : this.data.Brand,
      MRP_Price : this.data.MRP_Price,
      VMART_Price : this.data.VMART_Price,
      Netweight : this.data.Netweight,
      quantity : this.data.quantity,
      description : this.data.description
    })

    this.fileName = this.data.ProductImage.substring(this.data.ProductImage.lastIndexOf("\/")+1);

    if (this.data.ProductImage !== "") {
      // console.log(this.data.NDAAttachment);
      this.http.get("https://liveapi-vmart.softexer.com"+this.data.ProductImage,{
        responseType :"blob",
      }).subscribe((file)=>{
          let filename ="https://liveapi-vmart.softexer.com" + this.data.ProductImage;
          let spfname1 = this.data.ProductImage.substring(this.data.ProductImage.lastIndexOf("/") + 1);
          //let docName1 = spfname1.substring(spfname1.lastIndexOf("_")+1);
          let imgFile = new File([file], spfname1);
          this.productImg = imgFile;
          //let spfname = this.data.NDAAttachment.substring(this.data.NDAAttachment.lastIndexOf("/") + 1);
          //this.docName = spfname.substring(spfname.lastIndexOf("_")+1);
      })
    }

  }
  fileProgress(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.addProductForm.get('SubCategoryProfilePic').setValue(file);
        this.productImg =file;
        this.fileName = file.name
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  numericOnly(event) {    
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  
  closeTab() {
    this.dialogRef.close(false);
  }
  updateTodayDealProduct(){
    const productDetails = new FormData();
    productDetails.append("productimage",this.productImg);
    let payLoad = { ...this.addProductForm.value };
    payLoad.ProductID = this.data.ProductID;
    delete payLoad.ProductImage;
    console.log(payLoad);
    productDetails.append("updateproductInfo", JSON.stringify(payLoad));
    let token = localStorage.getItem('token');
    this.productService.adminUpdateTodayProductInfo(productDetails, token).subscribe((res) => {
      console.log("Res", res)
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.dialogRef.close(true);
      } else {
        this.openSnackBar(res.message, "");
        this.dialogRef.close(false);
      }
    }, (err) => {
      console.log("error", err)
    })
  }

}
