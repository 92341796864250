import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ProductsService } from 'src/app/services/products.service';
import { Router } from '@angular/router';
import { MatIcon } from '@angular/material'
import { MatSnackBar } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog} from '@angular/material';
@Component({
  selector: 'app-vmartaddress',
  templateUrl: './vmartaddress.component.html',
  styleUrls: ['./vmartaddress.component.css']
})
export class VmartaddressComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  loading: boolean=false;
  lat: any = "3.091210";
  lang: any = "101.677101";
  productsSubcribe: any;
  serverResponse: any;
  Address: any = [];
  issubmitted:boolean=true;
  isupdated:boolean=false;
  isLoading: boolean;
  constructor(private formBuilder: FormBuilder, private services: ProductsService,
    private router: Router,private dialog:MatDialog,private snackBar:MatSnackBar) { }

  ngOnInit(): void {
  
    this.form = this.formBuilder.group(
      {
        address: new FormControl('', [Validators.required]),
        landmark: new FormControl('', [Validators.required]),
        area: new FormControl('', [Validators.required]),
        city: new FormControl('', [Validators.required]),
        pincode: new FormControl('', [Validators.required]),
        latitude: "3.091210",
        langitude:"101.677101",
        addressId : ['']
      }
    );

    this.getProductsData();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  deletingaddress(adress){
  const dialogRef = this.dialog.open(DialogComponent, {
    height: '200px',
    width: '250px',
    disableClose: true,
    data:{ product:adress,isbanner:false,isadvitise:false,isdelete:true}
  });
  dialogRef.afterClosed().subscribe((res) => {
    if (res == true) {
      console.log(adress)
      this.isLoading = true;
      let obj = {
        addressId:adress.addressId
      }
      let token = localStorage.getItem('token');
      this.services.deleteaddress(obj,token).subscribe((dele) => {
  
        console.log("del", dele);
        if (dele.response === 3) {
          this.isLoading = false;
          this.openSnackBar(dele.message, "");
          this.getProductsData()
        } else {
          this.isLoading = false;
        }
      }
        , (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client Error ::", err.error)
          } else {
            console.log("Server Error ::", err.error)
          }
        })
       
    }
    else{
     

    }
  })

}

  editaddress(adress){
    console.log(adress)
    //let st = user.StartTime.toTimeString()
    this.form.patchValue({
      address: adress.address,
      landmark: adress.landmark,
      area: adress.area,
      city: adress.city,
      pincode: adress.pincode,
      latitude: adress.latitude,
      langitude:adress.langitude,
      addressId : adress.addressId
    })
    this.issubmitted=false;
    this.isupdated=true;
    document.getElementById("address").focus();
    
  }
 
  FormClearValidation(){
    this.form.controls["address"].clearValidators();
    //this.form.controls["address"].updateValueAndValidity();
    this.form.controls["landmark"].clearValidators();
    //this.form.controls["landmark"].updateValueAndValidity();
    this.form.controls["area"].clearValidators();
    //this.form.controls["area"].updateValueAndValidity();
    this.form.controls["city"].clearValidators();
    //this.form.controls["city"].updateValueAndValidity();
    this.form.controls["pincode"].clearValidators();
   // this.form.controls["pincode"].updateValueAndValidity();
    this.form.controls["addressId"].clearValidators();
   // this.form.controls["addressId"].updateValueAndValidity();
    // this.form.clearValidators();
  }
 


  getProductsData() {
   
    let token = localStorage.getItem('token');
    this.productsSubcribe = this.services.getaddress(token).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
        this.Address = this.serverResponse.Info
      }
    })
  }
 
numericOnly(event) {    
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  onSubmit(): void {
    if(this.isupdated){
      let payload = { ...this.form.value };
      let token = localStorage.getItem('token');
      this.services.updateaddress(payload,token).subscribe((res) => {
        if (res.response == 3) {
          this.isupdated = false;
          this.issubmitted=false;
          this.form.reset();
          this.FormClearValidation();
          this.openSnackBar(res.message, "");
          this.getProductsData(); 
          window.location.reload() 

        } else {
          this.openSnackBar(res.message, "");
        }
      })
    }else{
      let payload = { ...this.form.value };
      payload.latitude= "3.091210";
      payload.langitude="101.677101";
      delete payload.addressId;
      let token = localStorage.getItem('token');
      this.services.address(payload,token).subscribe((res) => {
        if (res.response == 3) {
          this.isupdated = false;
          this.issubmitted=true;
          this.form.reset();
          this.FormClearValidation();
          this.openSnackBar(res.message, "");
          window.location.reload() 
         
          this.getProductsData();
         
        } else {
          this.openSnackBar(res.message, "");
        }
      })
    }
  }

}
