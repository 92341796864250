import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http'
import { Observable, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 // public isLogedin = this.isUserLoggedIn.asObservable();
  public totalCartItems: BehaviorSubject<number> = new BehaviorSubject(0)
  public categoriesList: Array<any> = [];
  showLoader: any;
  fcmToken: any;

  constructor(private _http: HttpClient) { }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getLocation(position): Observable<any> {
    return this._http.get(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        position.latitude +
        "," +
        position.longitude +
        "&key=AIzaSyC1VLf_KiKNh5LNfbwg_Fcxoto47HZ1Jc8"
    );
  }

  Category(data: any) {
    return this._http.post("https://liveapi-vmart.softexer.com/api/categories/categoryupdate", data)
  }
  login(data: any) {
    return this._http.post("https://liveapi-vmart.softexer.com/api/seller/Sellerlogin", data)
  }
  signup(data: any) {
    return this._http.post("https://liveapi-vmart.softexer.com/api/seller/SellerRegister", data)
  }
  //Fetch Categories
  fetchCategory(data,token):Observable<any>{
    return this._http.post("https://liveapi-vmart.softexer.com/api/categories/subcategoryfetch",data,{ headers: { vmart: token } })
  }
  //Get All products
  getProduct(data) {
    return this._http.post("https://liveapi-vmart.softexer.com/api/categories/subcategoryfetch", data)
  }
  // getProducts(): any {
  //   return this._http.get("https://liveapi-vmart.softexer.com/api/categories/subcategoryfetch")
  // }
  // getProductes(data,token):any{
  //   return this._http.post("https://liveapi-vmart.softexer.com/api/categories/subcategoryfetch",data,{ headers: { vmart: token } })

  // }
  Productsdisplay(data,token):any{
    return this._http.post("https://liveapi-vmart.softexer.com/api/sellerProducts/productfetch",data,{ headers: { vmart: token } })
  }
  //send mobilenum
  sendNumber(number): Observable<any> {
    return this._http.post<any>("https://liveapi-vmart.softexer.com/V-Mart/signup/" + number, number)
  }
  verifyOtp(obj): any {
    return this._http.post("https://liveapi-vmart.softexer.com/V-Mart/verify", obj)

  }
  addToCart(productData): Observable<any> {
    return this._http.post("https://liveapi-vmart.softexer.com/api/CartData/addcart", productData)
  }
  updateCart(productData: any): Observable<any> {
    return this._http.post("https://liveapi-vmart.softexer.com/api/CartData/addcart", productData)
  }
  removeItem(data): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        productId: data.productId,
        mobileNumber: data.mobileNumber,
        netWeight: data.netWeight
      }
    }
    return this._http.delete("https://liveapi-vmart.softexer.com/api/CartData/cart", options)
  }
  removeAllItems(mobile): Observable<any> {
    return this._http.delete("https://liveapi-vmart.softexer.com/api/CartData/cartdataDeleteall" + mobile)
  }
  cartData(number): Observable<any> {
    return this._http.get("https://liveapi-vmart.softexer.com/api/CartData/cart?mobileNumber=" + number)
  }
  fetchVedasmartAddress(): Observable<any> {
    return this._http.get("https://liveapi-vmart.softexer.com/V-Mart/vmartaddress")
  }
  insertUserAddress(userAddress): Observable<any> {
    return this._http.post("https://liveapi-vmart.softexer.com/V-Mart/useraddress", userAddress)
  }
  updateUserAddress(address): Observable<any> {
    return this._http.put("https://liveapi-vmart.softexer.com/V-Mart/useraddress", address)
  }
  fetchUserAddress(mobileNum): Observable<any> {
    return this._http.get("https://liveapi-vmart.softexer.com/V-Mart/useraddress/" + mobileNum)
  }
  deleteUserAddress(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        addressId: id
      }
    }
    return this._http.delete("https://liveapi-vmart.softexer.com/V-Mart/useraddress", options)
  }
  admin(details): Observable<any> {
    return this._http.post("https://liveapi-vmart.softexer.com/V-Mart/admin/login", details)
  }
  adminAddProduct(data, token): Observable<any> {

    return this._http.post("https://liveapi-vmart.softexer.com/api/sellerProducts/productinsert", data, { headers: { vmart: token } })
  }
  adminUpdateProductInfo(data,token): Observable<any> {
    return this._http.put("https://liveapi-vmart.softexer.com/api/sellerProducts/productupdate", data,{ headers: { vmart: token } })
  }
  adminUpdateTodayProductInfo(data,token): Observable<any> {
    return this._http.put("https://liveapi-vmart.softexer.com/api/todaydeals/update", data,{ headers: { vmart: token } })
  }
  adminUpdateBannerImage(data,token): Observable<any> {
    return this._http.put("https://liveapi-vmart.softexer.com/api/dashboard/dashboardupdate", data,{ headers: { vmart: token } })
  }
  adminUpdateCategoryImage(data,token): Observable<any> {
    return this._http.put("https://liveapi-vmart.softexer.com/api/categories/categoryupdate", data,{ headers: { vmart: token } })
  }
  adminUpdatesubCategoryImage(data,token): Observable<any> {
    return this._http.put("https://liveapi-vmart.softexer.com/api/categories/subcategoryupdate", data,{ headers: { vmart: token } })
  }
  adminUpdateAdvertiseImage(data,token): Observable<any> {
    return this._http.put("https://liveapi-vmart.softexer.com/api/dashboard/dashboardupdate", data,{ headers: { vmart: token } })
  }
  categoryinsert(data,token):any{
    return this._http.post("https://liveapi-vmart.softexer.com/api/categories/categoryinsert",data,{ headers: { vmart: token } })
  }
  deleteProduct(id) {
    return this._http.delete("https://liveapi-vmart.softexer.com/api/categories/categoriesDeleteproductbyid/" + id)
  }
  adminUpdateProduct(updatedDetails): Observable<any> {
    return this._http.put("https://liveapi-vmart.softexer.com/api/sellerProducts/productupdate", updatedDetails)
  }
  productfetch(data): Observable<any> {
    return this._http.post("https://liveapi-vmart.softexer.com/api/sellerProducts/productfetch",data)
  }
  subcategoryinsert(data,token):any{
    return this._http.put("https://liveapi-vmart.softexer.com/api/categories/subcategoryinsert",data,{headers:{vmart:token}})
  }

  contactus(data): Observable<any> {
    return this._http.post("https://liveapi-vmart.softexer.com/api/contactus/contactUs",data)
  }
  deletebanners(data,token): Observable<any> {
    return this._http.put("https://liveapi-vmart.softexer.com/api/dashboard/dashboardremoveinserdata",data,{ headers: { vmart: token } })
  }
  Advitisementsinsert(data,token):Observable<any>{
    return this._http.post("https://liveapi-vmart.softexer.com/api/dashboard/dashboardinsert",data,{ headers: { vmart: token } })
  }

  forgot(token,data):any{
    return this._http.put("https://liveapi-vmart.softexer.com/api/seller/SellerUpdatePassword",data,{ headers: { vmart: token } })
  }
  getProductss(data,token):any{
    return this._http.post("https://liveapi-vmart.softexer.com/api/todaydeals/fetch",data,{ headers: { vmart: token } })
  }
  todaydeals(data, token): Observable<any> {

    return this._http.post("https://liveapi-vmart.softexer.com/api/todaydeals/insert", data, { headers: { vmart: token } })
  }
  address(data, token): Observable<any> {
    return this._http.post("https://liveapi-vmart.softexer.com/api/VmartAddress/insertvmartaddress",data,{ headers: { vmart: token } })
  }
  getaddress(token): Observable<any> {
    return this._http.get("https://liveapi-vmart.softexer.com/api/VmartAddress/fetchvmartaddress",{ headers: { vmart: token } })
  }
  deleteaddress(data,token):Observable<any>{
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        vmart: token
      }),
      body: {
        addressId:data.addressId
      },
    };

    return this._http.delete("https://liveapi-vmart.softexer.com/api/VmartAddress/deletevmartaddress",options)
  }
  getorders(data,token): Observable<any> {
    return this._http.post(" https://liveapi-vmart.softexer.com/api/seller/totalorders",data,{ headers: { vmart: token } })
  }
  deletedeals(data,token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        vmart: token
      }),
      body: {
        type:data.type
      },
      
    };
    return this._http.delete("https://liveapi-vmart.softexer.com/api/todaydeals/delete",options)
  }

  deleteproducts(data,token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        vmart: token
      }),
      body: {
        type:data.type
      },
      
    };
    return this._http.delete("https://liveapi-vmart.softexer.com/api/sellerProducts/productdelete",options)
  }
  
  updateaddress(data,token):any{
    return this._http.put(" https://liveapi-vmart.softexer.com/api/VmartAddress/updatevmartaddress",data,{ headers: { vmart: token } })
  }

  getordersapprove(data,token):any{
    return this._http.post(" https://liveapi-vmart.softexer.com/api/seller/orderconform",data,{headers:{vmart:token}})
  }
  deletesub(data,token):any{
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        vmart: token
      }),
      body: {
        type:data.type
      },
      
    };
    return this._http.delete("https://liveapi-vmart.softexer.com/api/categories/categoriesDelete",options)
  }
}
