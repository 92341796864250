import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-slots',
  templateUrl: './time-slots.component.html',
  styleUrls: ['./time-slots.component.css']
})
export class TimeSlotsComponent implements OnInit {
  today:any = [];
  constructor() { }

  ngOnInit() {
    this.getDays()
  }
  getDays(){
    //   let date = new Date()
    // console.log("date",date.getDay())
    // for(let i:number=0; i<=6; i++){
    //   let t:number = date.getDay() + i
    //   if(t <= (this.days.length - 1)){
    //     this.today[i] = this.days[date.getDay() + i]
    //   }else{
        
    //     this.today[i] = this.days[date.getDay() - (this.days.length-i)]
    //   }
    // }
    // console.log("today",this.today)
    this.today = [];
          var tempDate = [];
          tempDate.push(new Date());
          for (var i = 1; i <= 6; i++) {
            tempDate.push(new Date());
            tempDate[i].setDate(tempDate[i].getDate() + i);
          }
          this.today= tempDate;
    }
    getDay(day, str){
      let date = day.getMonth()+1
      let selectedDate= day.getFullYear()+"/"+date+"/"+day.getDate()
      console.log("Time Slot",selectedDate,str)
    }
}
