// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDEoLpxVnUnZ3VURmH4BunYgV4MQ5X8gUI",
    authDomain: "vmart-app-1541756069125.firebaseapp.com",
    //databaseURL: "https://vmart-app-1541756069125.firebaseio.com",
    projectId: "vmart-app-1541756069125",
    storageBucket: "vmart-app-1541756069125.appspot.com",
    messagingSenderId: "621056572403",
    appId: "1:621056572403:web:39a9069f70c24fc6b23086",
    measurementId: "G-EJ6VQXP11E"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
