import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { Router } from '@angular/router';
//import { VerificationComponent } from '../verification/verification.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductsService } from 'src/app/services/products.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  Forgot:any = FormGroup;
  submitted = false;
  loading = false;
  
 
  constructor(private fb: FormBuilder,private loginservice:ProductsService, public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private router:Router,private dialog: MatDialog,
    private snackBar:MatSnackBar) { }

  ngOnInit(): void {
    if (this.Forgot.invalid) {
      return;
    }
    this.submitted = true;
    console.log(this.data);
    this.Forgot = new FormGroup({
      userID: new FormControl('', [Validators.required]),
      Password:new FormControl('',[Validators.required])
    })
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

  public onCancel = () => {
    this.dialogRef.close();
  }
  onSubmit(){
    if (this.Forgot.invalid) {
      return;
    }
    if (this.submitted) {
      this.loading = true;
      //console.log("forgot successfull");
      console.log(this.Forgot.value);
      let token = localStorage.getItem('token')
      console.log(token)
      this.loginservice.forgot(token,this.Forgot.value).subscribe(
        (result) => {
          if(result.response==3){
            this.openSnackBar(result.message, "");
            this.dialogRef.close(true);
            // const dialogRef = this.dialog.open(VerificationComponent, {
            //   height: '300px',
            //   width: '400px',
            //   data : this.Forgot.value
            // }
            //)

          }
          else{
            this.openSnackBar(result.message, "");
            this.dialogRef.close(false);
          }
          console.log(result);

        },(err: HttpErrorResponse) => {
          this.loading = false;
          if (err.error instanceof Error) {
            this.openSnackBar(err.message, "Client Error");
          } else {
            this.openSnackBar(err.message, "Server Error");
          }
        }
      )}
  }


}
