import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ProductsService } from 'src/app/services/products.service';
import { Router } from '@angular/router';
import { MatIcon } from '@angular/material'
import { MatSnackBar } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog} from '@angular/material';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  loading: boolean=false;
  lat: any = "3.091210";
  lang: any = "101.677101";
  productsSubcribe: any;
  serverResponse: any;
  Address: any = [];
  issubmitted:boolean=true;
  isupdated:boolean=false;
  isLoading: boolean;
  constructor(private formBuilder: FormBuilder, private services: ProductsService,
    private router: Router,private dialog:MatDialog,private snackBar:MatSnackBar) { }

  ngOnInit(): void {
  
    this.form = this.formBuilder.group(
      {
        address: new FormControl('', [Validators.required]),
        landmark: new FormControl('', [Validators.required]),
        area: new FormControl('', [Validators.required]),
        city: new FormControl('', [Validators.required]),
        pincode: new FormControl('', [Validators.required]),
        latitude: "3.091210",
        langitude:"101.677101"
      }
    );

    this.getProductsData();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }

  deletingaddress(adress){
  const dialogRef = this.dialog.open(DialogComponent, {
    height: '200px',
    width: '250px',
    disableClose: true,
    data:{ product:adress,isbanner:false,isadvitise:false,isdelete:true}
  });
  dialogRef.afterClosed().subscribe((res) => {
    if (res == true) {
      console.log(adress)
      this.isLoading = true;
      let obj = {
        addressId:adress.addressId
      }
      let token = localStorage.getItem('token');
      this.services.deleteaddress(obj,token).subscribe((dele) => {
  
        console.log("del", dele);
        if (dele.response === 3) {
          this.isLoading = false;
          this.openSnackBar(dele.message, "");
          this.getProductsData()
        } else {
          this.isLoading = false;
        }
      }
        , (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client Error ::", err.error)
          } else {
            console.log("Server Error ::", err.error)
          }
        })
       
    }
    else{
     

    }
  })

}

  editaddress(adress){
    console.log(adress)
    //let st = user.StartTime.toTimeString()
    this.form.patchValue({
      address: adress.address,
      landmark: adress.landmark,
      area: adress.area,
      city: adress.city,
      pincode: adress.pincode,
      latitude: adress.latitude,
      langitude:adress.langitude
    })
    this.issubmitted=false;
    this.isupdated=true;
  }
 


  getProductsData() {
   
    let token = localStorage.getItem('token');
    this.productsSubcribe = this.services.getaddress(token).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
        this.Address = this.serverResponse.Info
      }
    })
  }

  onSubmit(): void {
    this.submitted = true;
  
    if (this.submitted) {
      this.loading = true;
      
      console.log(this.form.value)
      console.log(JSON.stringify(this.form.value, null, 2));
      let token = localStorage.getItem('token');
      this.services.address(this.form.value,token).subscribe((result) => {
       console.log(result)
       this.services.isUserLoggedIn.next(true);
      this.getProductsData()

      })
    } else {
      this.router.navigateByUrl('/timeSlots')
    }
  }

  // loginForm: FormGroup;
  // loginResponse: any;
  // otpResponse: any;
  // gotNumber: Boolean = false;
  // isLogin: boolean=false;
  // issignIn: boolean;
  // mblNum: any;
  // cartObj: any;
  // cartNum: string;
  // totalItems: Number = 0;
  // constructor(
  //   private modalService: NgbModal, 
  //   private fb: FormBuilder, 
  //   private product: ProductsService, 
  //   private router: Router,
  //   private snackBar:MatSnackBar)  {
      
  //   this.product.totalCartItems.subscribe(val => {
  //     this.totalItems = val
  //   }, err => {
  //     console.log(err)
  //   })
  //   this.product.isUserLoggedIn.subscribe(val => {
  //     if(val == true){
  //     this.isLogin = true;
  //     this.issignIn = true;
  //     }
  //   })
    
  // }

  // ngOnInit() {
  //   this.cartObj = localStorage.getItem('cartDetails');
  //   this.mblNum = localStorage.getItem('mobileNumber');
  //   this.cartNum = JSON.parse(this.cartObj);
   
  // }
  // openModel(content) {
  //   this.modalService.open(content, { centered: true, size: "sm" });
  // }
  // sendMobileNumber(data) {
  //   console.log("data", data)
  //   let number = "+91" + data;
  //   this.product.sendNumber(number).subscribe((posRes) => {
  //     this.loginResponse = posRes;
  //     localStorage.setItem('mobileNumber', number)
  //     if (this.loginResponse[0].response == "3") {
  //       this.gotNumber = true;
  //       this.loginForm.patchValue({
  //         mobileNumber: number
  //       })
  //     }
  //     console.log("Login Status", this.loginResponse)
  //   }, (err) => { console.log(err) })
  // }
  // logout() {
  //   localStorage.removeItem('cartDetails');
  //   localStorage.removeItem('mobileNumber'); 
  //   this.product.isUserLoggedIn.next(false);
  //   this.isLogin=false;
  //   this.router.navigateByUrl('/home')
   
  // }
  // gocart(){
  // if(this.isLogin){
  //   this.router.navigateByUrl('/cart')
  
  // }else{
  //   this.openSnackBar("please login ","")
  // }

  // }

  // openSnackBar(message: string, action: string) {
  //   this.snackBar.open(message, action, {
  //     duration: 3000,
  //     panelClass: "red-snackbar",
  //     verticalPosition: "top",
  //   });
  // }

  // sendOtp() {
  //   console.log("login", this.loginForm.value)
  //   this.product.verifyOtp(this.loginForm.value).subscribe((res) => {
  //     this.otpResponse = res;
  //     if (this.otpResponse[0].response == "3") {
  //       alert(this.otpResponse[0].message)
  //       localStorage.setItem('cartDetails', JSON.stringify(this.otpResponse[0]))
  //       this.isLogin = true;
  //       this.issignIn = true;
  //       this.modalService.dismissAll();
  //       this.product.isUserLoggedIn.next(true);
  //     } else {
  //       alert(this.otpResponse[0].message)
  //     }
  //     console.log("otp", this.otpResponse)
  //   }, (err) => { console.log(err) })
  // }


}
