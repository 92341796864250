import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { ProductsService } from 'src/app/services/products.service';
import { MatSnackBar, MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css'],
 
})
export class AdminPanelComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) el: ElementRef;
  editFile: boolean = true;
  removeUpload: boolean = false;
  fileName: string = "";
  image:string="";
  addProductForm: FormGroup;
  addcategoryForm:FormGroup;
  addsubcategoryForm:FormGroup;
  advitiseForm:FormGroup;
  addtodaydealsForm:FormGroup;
  bannerForm:FormGroup;
  productsSubcribe: any;
  serverResponse: any;
  categories: any;
  subCategories: any;
  productsList: Array<any> = []
  selectedCat: Array<any> = [];
  url: any;
  subCategoryID: any;
  CategoryID: any;
  updateproductInfo: Array<any> = []
  id: any;
  sub: any;
  file:string="";
  files: any;
  fileNames: string = "";
  produtList: any;
  advitiseimage: any;
  baseurls: string;
  fileNamess: any;
  issubmitted: boolean;
  isupdated: boolean;
  
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef, private productService: ProductsService,
    private snackBar: MatSnackBar,private router:Router,private activatedRoute: ActivatedRoute,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.baseurls="https://liveapi-vmart.softexer.com/"
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getProductsData();
    })
    let userInfo = JSON.parse(localStorage.getItem('user'));
    let obj = {
      //   PhoneNumber:userInfo
      //  //PhoneNumber:"+917330887338"
      type: "All"
    }
    console.log(userInfo);
    let token = localStorage.getItem('token');
   // this.CategoryID = 'cid' + (new Date()).getTime()
   // this.subCategoryID = 'sid' + (new Date()).getTime()
    this.productService.fetchCategory(obj, token).subscribe((list) => {
      if (list.response == 3) {
        this.categories = list.CategoryInfo
        console.log(list);
        console.log(this.categories)
      }
    }, err => {
      console.log(err)
    })
    this.fileName = "";
    this.image=""
    this.addProductForm = this.fb.group({
      SubCategoryProfilePic: [''],
      ProductName: new FormControl('', [Validators.required]),
      Brand: new FormControl('', [Validators.required]),
      subCategoryID: [""],
      CategoryID: [""],
      description:  new FormControl('', [Validators.required]),
      quantity: new FormControl('', [Validators.required]),
      MRP_Price: new FormControl('', [Validators.required]),
      VMART_Price:new FormControl('', [Validators.required]),
      Netweight:new FormControl('', [Validators.required]),
      categoryName: new FormControl('', [Validators.required]),
      subCategoryName:new FormControl('', [Validators.required]),
    })
   

    this.addcategoryForm = this.fb.group({
      categoryName: new FormControl('', [Validators.required]),
      subCategoryName: new FormControl('', [Validators.required]),
      images:[''],
      subimage:['']
    })
    this.addsubcategoryForm = this.fb.group({
      categoryName:new FormControl('', [Validators.required]),
      subCategoryName: new FormControl('', [Validators.required]),
      categoryID: [''],
      subimage :['']
    })
  
    this.bannerForm = this.fb.group({
      CategoryName:new FormControl('', [Validators.required]),
      subcategoryName: new FormControl('', [Validators.required]),
      type:"Advertiesment",
      image:[''],
      //PhoneNumber:""+userInfo
    })

    this.advitiseForm=this.fb.group({
      CategoryName:new FormControl('', [Validators.required]),
      subcategoryName: new FormControl('', [Validators.required]),
      type:"Advertiesments",
      image:[''],
    })

    this.addtodaydealsForm = this.fb.group({
      SubCategoryProfilePic: [''],
      ProductName: new FormControl('', [Validators.required]),
      Brand: new FormControl('', [Validators.required]),
      subCategoryID: [""],
      CategoryID: [""],
      description:  new FormControl('', [Validators.required]),
      quantity: new FormControl('', [Validators.required]),
      MRP_Price: new FormControl('', [Validators.required]),
      VMART_Price:new FormControl('', [Validators.required]),
      Netweight:new FormControl('', [Validators.required]),
      categoryName:new FormControl('', [Validators.required]),
      subCategoryName:new FormControl('', [Validators.required]),
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  
  numericOnly(event) {    
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  //get Products
  getProductsData() {
    //let payLoad = { categoryID: this.user.categoryID };
    let obj = {
      type: "All"
    }
    //let token = localStorage.getItem('token')
    this.productsSubcribe = this.productService.getProduct(obj).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (this.serverResponse.response == 3) {
        this.productsList = this.serverResponse.products
        this.produtList = this.serverResponse.DashBoardData[0].baners
        this.advitiseimage = this.serverResponse.DashBoardData[0].advertisements
      }
    }, (err) => {
      console.log(err)
    })
  }
  //Set SubCat Name
  setSubCat(category) {
    console.log(category)
    let index;
    index = this.categories.findIndex(val => {
      return val.categoryName == category;
    })
    if (index !== -1) {
      this.subCategories = this.categories[index].subCategorys
    }
    if (index !== -1) {
      this.addProductForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
      this.addsubcategoryForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
    }
  }


  setSubCat1(category) {
    console.log(category)
    let index;
    index = this.categories.findIndex(val => {
      return val.categoryName == category;
    })
    if (index !== -1) {
      this.subCategories = this.categories[index].subCategorys
    }
    if (index !== -1) {
     
      this.addsubcategoryForm.patchValue({ categoryID: this.categories[index].categoryID })
    }
    console.log(this.addsubcategoryForm.get("categoryID").value)
  }

  setSubCat2(category) {
    console.log(category)
    let index;
    index = this.categories.findIndex(val => {
      return val.categoryName == category;
    })
    if (index !== -1) {
      this.subCategories = this.categories[index].subCategorys
    }
    if (index !== -1) {
      this.bannerForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
      this.advitiseForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
    }
  }

  setSubCat3(category) {
    console.log(category)
    let index;
    index = this.categories.findIndex(val => {
      return val.categoryName == category;
    })
    if (index !== -1) {
      this.subCategories = this.categories[index].subCategorys
    }
    if (index !== -1) {
      this.advitiseForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
      this.advitiseForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
    }
  }


  setSubCatId(subCat) {
    console.log(subCat)
    let index;
    index = this.subCategories.findIndex(val => {
      return val.subCategoryName == subCat;
    })
    if (index !== -1) {
      this.addProductForm.patchValue({ subCategoryID: this.subCategories[index].subCategoryID
      })
    }
  }
  get pInfoArray() {
    return <FormArray>this.addProductForm.get('productInfo');
  }
  setSubCatId1(subCat) {
    console.log(subCat)
    let index;
    index = this.subCategories.findIndex(val => {
      return val.subCategoryName == subCat;
    })
    if (index !== -1) {
      this.bannerForm.patchValue({ subCategoryID: this.subCategories[index].subCategoryID
      })
    }
  }

  setSubCatId2(subCat) {
    console.log(subCat)
    let index;
    index = this.subCategories.findIndex(val => {
      return val.subCategoryName == subCat;
    })
    if (index !== -1) {
      this.advitiseForm.patchValue({ subCategoryID: this.subCategories[index].subCategoryID
      })
    }
  }

  
  //incase need one more object inthe pInfo Array
  // addItem() {
  //   this.pInfoArray.push(this.fb.group({

  //   }))
  // }
  fileProgress(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.addProductForm.get('SubCategoryProfilePic').setValue(file);
        this.editFile = false;
        this.removeUpload = true;
        this.fileName = file.name
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  
  

  addProduct() {
   
    let Netweights = this.addProductForm.get("Netweight").value;
    let userInfo = JSON.parse(localStorage.getItem('user'));
    const productDetails = new FormData();
    console.log(userInfo)
    console.log(this.addProductForm.value)
    productDetails.append("productimage", this.addProductForm.get("SubCategoryProfilePic").value);
    let payLoad = { ...this.addProductForm.value };
    delete payLoad.categoryName;
    delete payLoad.SubCategoryProfilePic;
    delete payLoad.subCategoryName;
    delete payLoad.category;
    delete payLoad.Netweight;
    payLoad.Netweight=Netweights;
   
    //payLoad.category=this.selectedCat;
    console.log(payLoad)
    //let formData = new FormData();
    productDetails.append("productInfo", JSON.stringify(payLoad));

    let token = localStorage.getItem('token');
    this.productService.adminAddProduct(productDetails, token).subscribe((res) => {
      console.log("Res", res)
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.getProductsData();
        this.router.navigateByUrl('/home')
      } else {
        this.openSnackBar(res.message, "");
      }
    }, (err) => {
      console.log("error", err)
    })
  }
  removeUploadedFile() {
    let newFileList = Array.from(this.el.nativeElement.files);
    this.editFile = true;
    this.removeUpload = false;
    this.addProductForm.get('SubCategoryProfilePic').setValue(null)
  }
  deleteProduct(id, name) {
    const dialogData = new MatDialogConfig();
    const text = { title: "Alert", message: "Are you sure want to delete" + " " + name }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'col-sm-3',
      data: text
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult)
      if (dialogResult === true) {
        this.productService.deleteProduct(id).subscribe((res) => {
          if (res[0].response == 3) {
            this.openSnackBar(res[0].message, "");
            this.getProductsData()
          } else {
            this.openSnackBar(res[0].message, "");
          }
        }, (err) => {
          console.log(err)
        })
      }
    });
    //Break

  }

  productfetch(){
    let obj={
    type:"All"
    }
    this.productService.productfetch(obj).subscribe((res) => {
      if(res.response == 3){
        this.openSnackBar(res.message, "");
        console.log(res)
        this.updateproductInfo=res.Info
        
      }else{
        this.openSnackBar(res.message, "");
      }
    })

  }

  //update Dailoge 
  openUpdateDailog(details) {
    console.log ("error ok")
    let obj = {
      type:"All"
    }
    this.productService.adminUpdateProduct(obj).subscribe((res) => {
      if(res.response == 3){
        this.openSnackBar(res.message, "");
        this.getProductsData();
      }else{
        this.openSnackBar(res.message, "");
      }
    })

    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.autoFocus = true;
    // let dailogRef=this.dialog.open(UpdateDailogComponent,{ 
    //   panelClass: 'col-sm-10',
    //   data: details
    // }) 
    // dailogRef.afterClosed().subscribe(res=>{
    //   if(res[0].response === "3"){
    //     this.openSnackBar(res[0].message,"");
    //     this.getProductsData()
    //   }
    // })
  }

  fileProces(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
       
        this.addcategoryForm.get('images').setValue(file);
       
        
        this.editFile = false;
        this.removeUpload = true;
        this.image = file.name
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  fileProcess(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
       
        this.addcategoryForm.get('subimage').setValue(file);
        //this.addcategoryForm.get('CategoryImage').setValue(file);
        this.editFile = false;
        this.removeUpload = true;
        this.file = file.name
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  addcategory(){
    let subcat = [];
    const productDetail = new FormData();
    console.log(this.addcategoryForm.value)
    productDetail.append("images", this.addcategoryForm.get("images").value);
    productDetail.append("subimage", this.addcategoryForm.get("subimage").value);
    let payLoad = { ...this.addcategoryForm.value };
    //delete payLoad.categoryName;
    delete payLoad.subimage;
    delete payLoad.images;
    
    let obj = {
      subCategoryName:payLoad.subCategoryName
    }
    subcat.push(obj);
    payLoad.subcategory=subcat;
    delete payLoad.subCategoryName;
    console.log(payLoad)
    productDetail.append("categoryInfo", JSON.stringify(payLoad));

    let token = localStorage.getItem('token');
    this.productService.categoryinsert(productDetail, token).subscribe((res) => {
      console.log("Res", res)
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.getProductsData();
        this.router.navigateByUrl('/home')
      } else {
        this.openSnackBar(res.message, "");
      }
    }, (err) => {
      console.log("error", err)
    })

    // const productDetails = new FormData();
    // console.log(this.addcategoryForm.value)
    // productDetails.append("images", this.addcategoryForm.get("CategoryImage").value);
    // productDetails.append("subimage", this.addcategoryForm.get("SubCategoryProfilePic").value);
    // let payLoad = { ...this.addcategoryForm.value };
    // delete payLoad.categoryName;
    // delete payLoad.CategoryImage;
    // delete payLoad.SubCategoryProfilePic;
    // delete payLoad.subCategoryName;
    // console.log(payLoad)
    // productDetails.append("Info", JSON.stringify(payLoad));
    // let token = localStorage.getItem('token');
    // this.productService.categoryinsert(productDetails, token).subscribe((res) => {
    //   console.log("Res", res)
    //   if (res.response == 3) {
    //     this.openSnackBar(res.message, "");
    //     this.getProductsData();
    //     this.router.navigateByUrl('/home')
    //   } else {
    //     this.openSnackBar(res.message, "");
    //   }
    // }, (err) => {
    //   console.log("error", err)
    // })
  }



  fileProgres(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.addsubcategoryForm.get('subimage').setValue(file);
        this.editFile = false;
        this.removeUpload = true;
        this.fileName = file.name
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  addsubcategory(){
    const productDetail = new FormData();
    console.log(this.addsubcategoryForm.value)
    let payLoad = { ...this.addsubcategoryForm.value };
    delete payLoad.SubCategoryProfilePic;
    delete payLoad.categoryName;
    productDetail.append("subimage",this.addsubcategoryForm.get("subimage").value);
    delete payLoad.subimage;
    console.log(payLoad)
    productDetail.append("subCategoryInfo", JSON.stringify(payLoad));

    let token = localStorage.getItem('token');
    this.productService.subcategoryinsert(productDetail,token).subscribe((res) => {
      console.log("Res", res)
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.getProductsData();
        this.router.navigateByUrl('/home')
      } else {
        this.openSnackBar(res.message, "");
      }
    }, (err) => {
      console.log("error", err)
    })

  }

  filePro(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.bannerForm.get('image').setValue(file);
        this.editFile = false;
        this.removeUpload = true;
        this.fileNames = file.name
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  banner(){

    let userInfo = JSON.parse(localStorage.getItem('user'));
   console.log(userInfo)
    const Advitisements = new FormData();
    console.log(this.bannerForm.value)
   
    let payLoad = { ...this.bannerForm.value };
    let phno=userInfo.PhoneNumber.substring(3)
    payLoad.PhoneNumber=phno;
    delete payLoad.categoryName;
    Advitisements.append("image",this.bannerForm.get("image").value);
    delete payLoad.image;
    
    
    console.log(payLoad)
    Advitisements.append("dashboardInfo", JSON.stringify(payLoad));
    let token = localStorage.getItem('token');
    this.productService.Advitisementsinsert(Advitisements,token).subscribe((res) => {
     
      console.log("Res", res)
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.getProductsData();
        this.router.navigateByUrl('/home')
      } else {
        this.openSnackBar(res.message, "");
      }
    }, (err) => {
      console.log("error", err)
    })
  }

  fileProo(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.advitiseForm.get('image').setValue(file);
        this.editFile = false;
        this.removeUpload = true;
        this.fileNamess = file.name
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  advitise(){

    let userInfo = JSON.parse(localStorage.getItem('user'));
   console.log(userInfo)
    const Advitisements = new FormData();
    console.log(this.advitiseForm.value)
   
    let payLoad = { ...this.advitiseForm.value };
    let phno=userInfo.PhoneNumber.substring(3)
    payLoad.PhoneNumber=phno;
    delete payLoad.categoryName;
    Advitisements.append("image",this.advitiseForm.get("image").value);
    delete payLoad.image;
    
    
    console.log(payLoad)
    Advitisements.append("dashboardInfo", JSON.stringify(payLoad));
    let token = localStorage.getItem('token');
    this.productService.Advitisementsinsert(Advitisements,token).subscribe((res) => {
     
      console.log("Res", res)
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.getProductsData();
        //this.router.navigateByUrl('/home')
      } else {
        this.openSnackBar(res.message, "");
      }
    }, (err) => {
      console.log("error", err)
    })
  }




  setSubCat5(category) {
    console.log(category)
    let index;
    index = this.categories.findIndex(val => {
      return val.categoryName == category;
    })
    if (index !== -1) {
      this.subCategories = this.categories[index].subCategorys
    }
    if (index !== -1) {
      this.addtodaydealsForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
      this.addsubcategoryForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
    }
  }

  setSubCatId5(subCat) {
    console.log(subCat)
    let index;
    index = this.subCategories.findIndex(val => {
      return val.subCategoryName == subCat;
    })
    if (index !== -1) {
      this.addtodaydealsForm.patchValue({ subCategoryID: this.subCategories[index].subCategoryID
      })
    }
  }
  
  fileProgress5(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.addtodaydealsForm.get('SubCategoryProfilePic').setValue(file);
        this.editFile = false;
        this.removeUpload = true;
        this.fileName = file.name
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  adddeals(){
    let Netweights = this.addtodaydealsForm.get("Netweight").value;
    let userInfo = JSON.parse(localStorage.getItem('user'));
    const productDetails = new FormData();
    console.log(userInfo)
    console.log(this.addtodaydealsForm.value)
    productDetails.append("productimage", this.addtodaydealsForm.get("SubCategoryProfilePic").value);
    let payLoad = { ...this.addtodaydealsForm.value };
    delete payLoad.categoryName;
    delete payLoad.SubCategoryProfilePic;
    delete payLoad.subCategoryName;
    delete payLoad.category;
    delete payLoad.Netweight;
    payLoad.Netweight=Netweights;
   
   
    //payLoad.category=this.selectedCat;
    console.log(payLoad)
    //let formData = new FormData();
    productDetails.append("productInfo", JSON.stringify(payLoad));

    let token = localStorage.getItem('token');
    this.productService.todaydeals(productDetails, token).subscribe((res) => {
      console.log("Res", res)
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.getProductsData();
        this.router.navigateByUrl('/home')
      } else {
        this.openSnackBar(res.message, "");
      }
    }, (err) => {
      console.log("error", err)
    })

  }

}
