import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  mobileNum: any;
  cartResponse: any;
  cartItems: any = [];
  itemQuntity: any;
  selectedQuantiy: string;
  cartObj: any;
  cartNum: any;
  totalItems: number;
  totalCost: number;
  totalMrp: number;
  totalSavings: number;
  isItemsPresent: Boolean;
  constructor(private router: Router, private productService: ProductsService,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getCartsData()
  }
  //Message Alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  getCartsData() {
    this.totalItems = 0;
    this.totalCost = 0;
    this.totalMrp = 0;
    this.totalSavings = 0;
    //this.cartObj = localStorage.getItem('cartDetails')
    // this.mobileNum = localStorage.getItem('mobileNumber')
    let userInfo = JSON.parse(localStorage.getItem('user'));
    if (userInfo) {
      this.productService.cartData(userInfo).subscribe((res) => {
        this.cartResponse = res;
        if (this.cartResponse.response == 3) {
          this.cartItems = this.cartResponse.cartList;
          this.cartItems.forEach(element => {
            console.log(element.quantity)
            this.totalItems += parseFloat(element.quantity);
            this.totalCost += parseFloat(element.vmartPrice) * parseFloat(element.quantity);
            this.totalMrp += parseFloat(element.mrpPrice) * parseFloat(element.quantity);
            this.totalSavings += (parseFloat(element.mrpPrice) * parseFloat(element.quantity)) - (parseFloat(element.vmartPrice) * parseFloat(element.quantity));
          });
          console.log("elementsCount", this.totalItems);
          this.productService.totalCartItems.next(this.totalItems)
        } else {
          this.openSnackBar(this.cartResponse.message, "")
        }
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log(err.error.message)
        } else {
          console.log(err.message)
        }
      })
    } else {
      this.openSnackBar("please Add products in your cart", "")
      this.router.navigateByUrl('/home')
    }
  }
  addToCart(productData) {
    let timeStamp = Math.round((new Date()).getTime() / 1000);
    if (this.mobileNum && this.cartObj) {
      this.cartNum = JSON.parse(this.cartObj);
      let details = {
        productId: productData.productId,
        cartId: this.cartNum.cartId,
        itemName: productData.itemName,
        mrpPrice: productData.mrpPrice,
        vmartPrice: productData.vmartPrice,
        quantity: 1 + parseInt(productData.quantity),
        mobileNumber: this.mobileNum,
        netWeight: productData.netWeight,
        timeStamp: timeStamp
      }
      if (productData.quantity <= 14) {
        this.productService.updateCart(details).subscribe((cartRes) => {
          this.cartResponse = cartRes;
          if (this.cartResponse[0].response == "3") {
            this.getCartsData()
            this.openSnackBar(this.cartResponse[0].message, "Added")
          }
          console.log("CartResponse", this.cartResponse)
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log(err.error.message)
          } else {
            console.log(err.message)
          }
        })
      } else {
        this.openSnackBar("Cart limit is 15 for 1 item", "")
      }

    } else {
      alert("Login With your mobile number")
    }
  }
  //Remove one item from Cart
  removeOne(productData) {
    let timeStamp = Math.round((new Date()).getTime() / 1000);
    if (this.mobileNum && this.cartObj) {
      this.cartNum = JSON.parse(this.cartObj);
      let details = {
        productId: productData.productId,
        cartId: this.cartNum.cartId,
        itemName: productData.itemName,
        mrpPrice: productData.mrpPrice,
        vmartPrice: productData.vmartPrice,
        quantity: productData.quantity - 1,
        mobileNumber: this.mobileNum,
        netWeight: productData.netWeight,
        timeStamp: timeStamp
      }
      this.productService.updateCart(details).subscribe((cartRes) => {
        this.cartResponse = cartRes;
        if (this.cartResponse[0].response == "3") {
          this.openSnackBar(this.cartResponse[0].message, "Removed")
          if (productData.quantity == 1) {
            this.removeItem(productData)
          } else {
            this.getCartsData()
          }
        }
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log(err.error.message)
        } else {
          console.log(err.message)
        }
      })
      console.log("cartData", details)
    } else {
      this.openSnackBar("Login With your mobile number", "")
    }
  }
  //Remove whole item from cart
  removeItem(productData) {
    let removeDetails = {
      productId: productData.productId,
      mobileNumber: this.mobileNum,
      netWeight: productData.netWeight
    }
    this.productService.removeItem(removeDetails).subscribe((dbRes) => {
      if (dbRes[0].response == "3") {
        this.openSnackBar(dbRes[0].message, "")
        this.getCartsData()
      } else {
        this.openSnackBar(dbRes[0].message, "")
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log(err.error.message)
      } else {
        console.log(err.message)
      }
    })
  }
  //Remove All items from cart
  removeAll() {
    this.productService.removeAllItems(this.mobileNum).subscribe((delRes) => {
      this.openSnackBar(delRes[0].message, "")
      this.getCartsData()
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log(err.error.message)
      } else {
        console.log(err.message)
      }
    })
  }

}
