import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatSnackBar } from "@angular/material";
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogComponent } from '../dialog/dialog.component';
import { UpdateTodaydealComponent } from '../update-todaydeal/update-todaydeal.component';
import { BannerupdateComponent } from '../bannerupdate/bannerupdate.component';
import { AdvatiseupdateComponent } from '../advatiseupdate/advatiseupdate.component';
import { CategoryUpdateComponent } from '../category-update/category-update.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  productsSubcribe: any;
  serverResponse: any;
  productsList: any = [];
  productList: any = [];
  cartResponse: any;
  updateResponse: any;
  itemQuntity: any;
  selectedQuantiy: string;
  mobileNum: string;
  cartNum: any;
  cartObj: any;
  isAdded: boolean = false;
  totalItems: number;
  isLogin: boolean = false;
  cartList: any = [];
  baseurl: string;
  sub: any;
  id: any;
  baseurls: string;
  produtList: any;
  advitiseimage: any;
  todaydeals: any;
  vmartForm: FormGroup;
  issubmitted: boolean = true;
  isupdated: boolean = false;
  isLoading: boolean;
  constructor(private products: ProductsService, public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute
    , private router: Router, private dialog: MatDialog,) {
    this.products.isUserLoggedIn.subscribe(value => {
      if (value === true) {
        this.isLogin = true;
        this.getProductsData()
        // this.getProductsDataa()
      }
      this.sub = this.activatedRoute.params.subscribe(params => {
        this.id = params['id'];

      })

    });
  }

  ngOnInit() {
    this.baseurls = "https://liveapi-vmart.softexer.com/"
    this.baseurl = "https://liveapi-vmart.softexer.com"
    this.totalItems = 0;
    this.getProductsData()
    // this.getProductsDataa()
    this.itemQuntity = "1";
    this.getsubcatinfo();

  }
  //message showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  categorynav(products) {
    console.log(products);
    if (this.isLogin) {
      this.router.navigate(["product", products.categoryID])
      //this.openSnackBar("Thank You", "")
    } else {
      this.openSnackBar("Please login with your mobile number", "")
    }

  }


  //get Products
  getProductsData() {
    let obj = {
      type: "All"
    }
    //let token = localStorage.getItem('token');
    this.productsSubcribe = this.products.getProduct(obj).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
        this.productsList = this.serverResponse.CategoryInfo
        this.productList = this.serverResponse.bestSellings
        this.produtList = this.serverResponse.DashBoardData[0].baners
        this.advitiseimage = this.serverResponse.DashBoardData[0].advertisements
        this.todaydeals = this.serverResponse.DailyDeals
        console.log(this.productsList);
        // this.productsList.forEach(val => {
        //   val.isSelected = false;
        //   val.numOfItems = 1;
        //   this.cartList.findIndex(num => {
        //     if (num.productId === val.productId) {
        //       val.isSelected = true;
        //       val.numOfItems = parseInt(num.quantity);
        //     }
        //   })
        // })
      }

    }, (err) => {
      console.log(err)
    })
  }

  //   getProductsDataa() {
  //     let obj={
  //       type:"All"
  //     }
  //     let token = localStorage.getItem('token');
  //     this.productsSubcribe = this.products.getProductss(obj,token).subscribe((posRes) => {
  //       this.serverResponse = posRes;
  //       console.log(this.serverResponse)
  //       if (this.serverResponse.response == 3) {
  //         this.todaydeals=this.serverResponse.Info
  //     }(err) => {
  //       console.log(err)
  //     }
  //   })
  // }


  getCartData() {
    // let userInfo = JSON.stringify(localStorage.getItem('user'));
    // this.mobileNum = localStorage.getItem('mobileNumber');
    // this.cartObj = localStorage.getItem('cartDetails');
    //console.log(this.cartObj)
    if (this.mobileNum && this.cartObj) {
      this.products.cartData(this.mobileNum).subscribe((res) => {
        this.cartResponse = res;
        if (this.cartResponse[0].response == 3) {
          this.cartList = this.cartResponse[0].cartList;
          this.cartList.forEach(count => {
            this.totalItems += parseFloat(count.quantity)
          })
          this.products.totalCartItems.next(this.totalItems)
        } else {
          alert(this.cartResponse[0].message)
        }
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log(err.error.message)
        } else {
          console.log(err.message)
        }
      })
    } else {
      this.cartList = []
      this.totalItems = 0;
      this.products.totalCartItems.next(this.totalItems)
    }
  }
  addToCart(productData) {
    console.log(productData)
    let userInfo = JSON.parse(localStorage.getItem('user'));
    console.log(userInfo);
    // this.mobileNum = localStorage.getItem('mobileNumber');
    // this.cartObj = localStorage.getItem('cartDetails');
    //let timeStamp = Math.round((new Date()).getTime() / 1000);
    // if (this.mobileNum && this.cartObj) {
    //   this.cartNum = JSON.parse(this.cartObj);
    let details = {
      productId: productData.ProductID,
      //cartId: this.cartNum.cartId,
      //itemName: productData.ProductName,
      mrpPrice: "" + productData.MRP_Price,
      vmartPrice: "" + productData.VMART_Price,
      quantity: productData.quantity,
      mobileNumber: userInfo.PhoneNumber,
      netWeight: productData.quantity,
      // timeStamp: timeStamp,
      shippingPrice: "100",
    }


    this.products.addToCart(details).subscribe((cartRes) => {
      this.cartResponse = cartRes;
      if (this.cartResponse.response == 3) {
        this.openSnackBar(this.cartResponse.message, "Thank you")
        //productData.isSelected = true;
        this.totalItems += 1;
        this.products.totalCartItems.next(this.totalItems)
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log(err.error.message)
      } else {
        console.log(err.message)
      }
    })
    console.log("cartData", details)
    // } else {
    //   this.openSnackBar("Login with your mobile number", "")
    // }
  }
  //increment one item to cart
  addOneToCart(productData) {
    let timeStamp = Math.round((new Date()).getTime() / 1000);
    if (this.mobileNum && this.cartObj) {
      this.cartNum = JSON.parse(this.cartObj);
      let details = {
        productId: productData.productId,
        // cartId: this.cartNum.cartId,
        // itemName: productData.itemName,
        mrpPrice: productData.productsList.mrp,
        vmartPrice: productData.productsList.vmartPrice,
        quantity: 1 + productData.numOfItems,
        mobileNumber: this.mobileNum,
        netWeight: productData.productsList.quantity,
        timeStamp: timeStamp,
        shippingPrice: productData.productsList.shippingPrice,
      }
      if (productData.numOfItems <= 14) {
        this.products.updateCart(details).subscribe((cartRes) => {
          this.updateResponse = cartRes;
          if (this.updateResponse[0].response == 3) {
            this.openSnackBar(this.updateResponse[0].message, productData.numOfItems + 1)
            productData.numOfItems++
            this.totalItems += 1;
            this.products.totalCartItems.next(this.totalItems)
          }
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log(err.error.message)
          } else {
            console.log(err.message)
          }
        })
      } else {
        this.openSnackBar("Cart limit is 15 for one item", "")
      }
    } else {
      this.openSnackBar("Login with your mobile number", "")
    }
  }
  //Remove one item from Cart
  removeOneFromCart(productData) {
    console.log("numofItems", productData.numOfItems)
    let timeStamp = Math.round((new Date()).getTime() / 1000);
    if (this.mobileNum && this.cartObj) {
      this.cartNum = JSON.parse(this.cartObj);
      let details = {
        productId: productData.productId,
        cartId: this.cartNum.cartId,
        itemName: productData.itemName,
        mrpPrice: productData.pinfo[0].mrp,
        vmartPrice: productData.pinfo[0].vmartPrice,
        quantity: productData.numOfItems - 1,
        mobileNumber: this.mobileNum,
        netWeight: productData.pinfo[0].quantity,
        timeStamp: timeStamp
      }
      this.products.updateCart(details).subscribe((cartRes) => {
        this.updateResponse = cartRes;
        if (this.updateResponse[0].response == "3") {
          this.totalItems -= 1;
          this.products.totalCartItems.next(this.totalItems)
          if (productData.numOfItems == 1) {
            productData.isSelected = false;
            this.removeItem(productData);
          } else {
            productData.numOfItems--
            this.openSnackBar(this.updateResponse[0].message, "")
          }
        }
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log(err.error.message)
        } else {
          console.log(err.message)
        }
      })
      console.log("cartData", details)
    } else {
      alert("Login With your mobile number")
    }
  }
  //Remove whole item from cart
  removeItem(productData) {
    let removeDetails = {
      productId: productData.productId,
      mobileNumber: this.mobileNum,
      netWeight: productData.pinfo[0].quantity
    }
    this.products.removeItem(removeDetails).subscribe((dbRes) => {
      if (dbRes[0].response == "3") {
        this.openSnackBar(dbRes[0].message, "")

      } else {
        this.openSnackBar(dbRes[0].message, "")
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log(err.error.message)
      } else {
        console.log(err.message)
      }
    })
  }

  getsubcatinfo(){
     
  let obj={
    type:"All"
  }
 //let token = localStorage.getItem('token')
  this.products.getProduct(obj).subscribe((posRes) => {
    this.serverResponse = posRes;
      console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
        this.productList = this.serverResponse.bestSellings
        // this.productsList.forEach(val => {
        //   val.isSelected = false;
        //   val.numOfItems = 1;
        // })
      } else {
        alert(this.serverResponse.message)
      }
    },
      (err) => {
        console.log(err)
      })
  }




  editcategory(products) {
    console.log(products)
    this.vmartForm.patchValue({
      categoryName: products.categoryName,
      subCategoryName: products.subcategory.subCategoryName,
    })
    this.issubmitted = false;
    this.isupdated = true;
  }


  deletingbanner(products) {

    console.log(products);
    let userInfo = JSON.parse(localStorage.getItem('user'));
    const dialogRef = this.dialog.open(DialogComponent, {
      height: '200px',
      width: '250px',
      disableClose: true,
      data: { product: products, isbanner: false, isadvitise: false, isdelete: true }
    });
    dialogRef.afterClosed().subscribe((res) => {
      let userInfo = JSON.parse(localStorage.getItem('user'));
      if (res == true) {
        console.log(products)
        this.isLoading = true;
        let phno = userInfo.PhoneNumber.substring(3)
        let obj = {

          PhoneNumber: phno,
          type: "baners",
          Id: products.banersID,
        }
        console.log(obj)
        let token = localStorage.getItem('token');
        this.products.deletebanners(obj, token).subscribe((dele) => {

          console.log("del", dele);
          if (dele.response === 3) {
            this.isLoading = false;
            this.openSnackBar(dele.message, "");
            this.getProductsData()
            console.log("success", dele);
          } else {
            this.isLoading = false;
          }
        }
          , (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client Error ::", err.error)
            } else {
              console.log("Server Error ::", err.error)
            }
          })

      }
      else {
        this.getProductsData()

      }
    })
  }

  deletingadvitise(products) {

    console.log(products);

    const dialogRef = this.dialog.open(DialogComponent, {
      height: '200px',
      width: '250px',
      disableClose: true,
      data: { product: products, isbanner: false, isadvitise: false, isdelete: true }
    });
    dialogRef.afterClosed().subscribe((res) => {
      let userInfo = JSON.parse(localStorage.getItem('user'));
      if (res == true) {
        console.log(products)
        this.isLoading = true;
        let phno = userInfo.PhoneNumber.substring(3)
        let obj = {

          PhoneNumber: phno,
          type: "Advertiesments",
          Id: products.advertiesmentID,
        }
        console.log(obj)
        let token = localStorage.getItem('token');
        this.products.deletebanners(obj, token).subscribe((dele) => {

          console.log("del", dele);
          if (dele.response === 3) {
            this.isLoading = false;
            this.openSnackBar(dele.message, "");
            this.getProductsData()
            console.log("success", dele);
          } else {
            this.isLoading = false;
          }
        }
          , (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client Error ::", err.error)
            } else {
              console.log("Server Error ::", err.error)
            }
          })

      }
      else {
        this.getProductsData()

      }
    })
  }

  editingbanner(products) {
    console.log(products);
    const dailogRef = this.dialog.open(BannerupdateComponent, {
      height: '350px',
      width: '450px',
      disableClose: true,
      data: { products: products, categoryList: this.productsList }
    });
    dailogRef.afterClosed().subscribe((res) => {
      console.log(res);
      if (res==true) {
        this.getProductsData();
        this.getsubcatinfo();
        window.location.reload()
      }
    });

  }
  editingadvitise(products) {
    const dailogRef = this.dialog.open(AdvatiseupdateComponent, {
      height: '300px',
      width: '450px',
      disableClose: true,
      data: { products: products, categoryList: this.productsList }

    });
    dailogRef.afterClosed().subscribe((res) => {
      console.log(res==true);
      if (res) {
        this.getProductsData();
        this.getsubcatinfo();
        window.location.reload()
      }
    });

  }

  updatetodaydeals(products) {
    let dailogRef = this.dialog.open(UpdateTodaydealComponent, {
      panelClass: "col-md-4",
      hasBackdrop: true,
      disableClose: true,
      data: products,
    });
    dailogRef.afterClosed().subscribe((res) => {
      console.log(res);
      if (res==true) {
        this.getProductsData();
        this.getsubcatinfo();
      }
    });
  }
  deletetodaydeals(products) {
    console.log(products);

    const dialogRef = this.dialog.open(DialogComponent, {
      height: '200px',
      width: '250px',
      disableClose: true,
      data: { product: products, isbanner: false, isadvitise: false, isdelete: true }
    });
    dialogRef.afterClosed().subscribe((res) => {
      let userInfo = JSON.parse(localStorage.getItem('user'));
      if (res == true) {
        console.log(products)
        this.isLoading = true;
        let obj = {
          type: products.ProductID
        }
        console.log(obj)
        let token = localStorage.getItem('token');
        this.products.deletedeals(obj, token).subscribe((dele) => {

          console.log("del", dele);
          if (dele.response === 3) {
            this.isLoading = false;
            this.openSnackBar(dele.message, "");
            this.getProductsData()
            console.log("success", dele);
          } else {
            this.isLoading = false;
          }
        }
          , (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client Error ::", err.error)
            } else {
              console.log("Server Error ::", err.error)
            }
          })

      }
      else {
        this.getProductsData()

      }
    })
  }

  deletingsubcategory(products) {
    const dialogRef = this.dialog.open(DialogComponent, {
      height: '200px',
      width: '250px',
      disableClose: true,
      data: { product: products, isbanner: false, isadvitise: false, isdelete: true }
    });
    dialogRef.afterClosed().subscribe((res) => {
      let userInfo = JSON.parse(localStorage.getItem('user'));
      if (res == true) {
        this.getProductsData()
        console.log(products)
        this.isLoading = true;
        let obj = {
          type: products.categoryID,
        }
        console.log(obj)
        let token = localStorage.getItem('token');
        this.products.deletesub(obj, token).subscribe((dele) => {

          console.log("del", dele);
          if (dele.response === 3) {
            this.isLoading = false;
            this.openSnackBar(dele.message, "");
            this.getProductsData()
            console.log("success", dele);
          } else {
            this.isLoading = false;
          }
        }
          , (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client Error ::", err.error)
            } else {
              console.log("Server Error ::", err.error)
            }
          })

      }
      else {
        this.getsubcatinfo()

      }
    })

  }

  editingsub(product) {
    console.log(product);
    const dailogRef = this.dialog.open(CategoryUpdateComponent, {
      height: '400px',
      width: '400px',
      disableClose: true,
      data: { products: product, categoryList: this.productsList }
    });
    dailogRef.afterClosed().subscribe((res) => {
      console.log(res);
      if (res==true) {
        this.getProductsData();
        window.location.reload()
       // this.getsubcatinfo();
      }else{
        console.log("fghjkghfhgjkhgj")
      }
    });

  }


}


