import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from "@angular/material";
import { SingleProductComponent } from '../single-product/single-product.component';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { ProductsService } from '../../services/products.service';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-subcategory-update',
  templateUrl: './subcategory-update.component.html',
  styleUrls: ['./subcategory-update.component.css']
})
export class SubcategoryUpdateComponent implements OnInit {
  advcategories: any;
  advProduct: any;
  user : any;
  url;
  format;
  addcategoryForm:FormGroup;
  productImg: any = "";
  constructor(
    private dialogRef: MatDialogRef<SingleProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private productService: ProductsService,
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.advcategories = this.data.categoryList[0];
    this.advProduct = this.data.products;
    this.url = "https://liveapi-vmart.softexer.com"+this.advProduct.SubCategoryProfilePic;
    this.format = 'image';
    this.user = JSON.parse(localStorage.getItem("user")) || null;
    console.log(this.user);
    if (!this.user && !this.user.PhoneNumber) {
      this.openSnackBar("Please Login..!","");
      this.dialogRef.close(false);
    }    

    this.addcategoryForm = this.fb.group({
      subCategoryName: new FormControl('', [Validators.required]),
    })
    this.addcategoryForm.patchValue({
      subCategoryName:this.advProduct.subCategoryName
    })
  }
  UpdatesubCategory(){
    let formvalue={
      ...this.addcategoryForm.value
    }
    let payLoad = {
      categoryID : this.advcategories.categoryID,
      subCategoryName :formvalue.subCategoryName,
      subCategoryID :this.advProduct.subCategoryID
    }
    console.log(payLoad);
    const productDetails = new FormData();
    productDetails.append("subimage",this.productImg);
    productDetails.append("subCategoryupdateInfo", JSON.stringify(payLoad));
    let token = localStorage.getItem('token');
    this.productService.adminUpdatesubCategoryImage(productDetails, token).subscribe((res) => {
      console.log("Res", res)
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.dialogRef.close(true);
      } else {
        this.openSnackBar(res.message, "");
        this.dialogRef.close(false);
      }
    }, (err) => {
      console.log("error", err)
    })
  }
  onSelectFile(event) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      
      if(file.type.indexOf('image')> -1){
        this.format = 'image';
      } else if(file.type.indexOf('video')> -1){
        this.format = 'video';
      }
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
        console.log(this.url);
        let imgFile = new File([file], this.url);
        console.log(imgFile)
        this.productImg = file;
      }
    }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  closeTab() {
    this.dialogRef.close(false);
  }

}
