import { Component, Inject, OnInit, ViewEncapsulation, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HomeComponent } from '../home/home.component';

import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { ProductsService } from 'src/app/services/products.service';
import { MatSnackBar, MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { database } from 'firebase';
import { InjectFlags } from '@angular/compiler/src/core';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  advitiseForm: FormGroup;
  bannerForm: FormGroup;
  editFile: boolean = true;
  removeUpload: boolean = false;
  fileNames: string = "";
  fileNamess: string = "";
  productsSubcribe: any;
  serverResponse: any;
  advitiseimage: Array<any> = [];
  produtList: Array<any> = [];
  isbanner: boolean = false;
  isadvitise: boolean = false;
  isdeleted:boolean=false;
  islogout:boolean=false;
  categories: any;
  subCategories: any;
  advcategories:any;
  bannercategories:any

  constructor(
    public dialogRef: MatDialogRef<HomeComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cd: ChangeDetectorRef,
    private productService: ProductsService,
    private snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog) { }


  ngOnInit() {
    console.log(this.data);
    this.isbanner = this.data.isbanner;
    this.isadvitise = this.data.isadvitise;
    this.isdeleted=this.data.isdelete;
    this.islogout=this.data.islogout
    this.bannerForm = this.fb.group({
      CategoryName: new FormControl('', [Validators.required]),
      subcategoryName: new FormControl('', [Validators.required]),
      type: "Advertiesment",
      image: [''],
      //PhoneNumber:""+userInfo
    })

    this.advitiseForm = this.fb.group({
      CategoryName: new FormControl('', [Validators.required]),
      subcategoryName: new FormControl('', [Validators.required]),
      type: "Advertiesments",
      image: [''],
    })
    let obj = {
      //   PhoneNumber:userInfo
      //  //PhoneNumber:"+917330887338"
      type: "All"
    }
    let token = localStorage.getItem('token');
    // this.productService.fetchCategory(obj, token).subscribe((list) => {
    //   if (list.response == 3) {
    //     this.categories = list.CategoryInfo;
    //     this.advcategories = list.CategoryInfo;
    //     console.log(list);
    //     console.log(this.categories)
    //   }
    // }, err => {
    //   console.log(err)
    // })

    this.getpathchvalues();
    this.getpathchvaluess();
  }
  getpathchvalues(){
    console.log(this.data);
    console.log(this.data.product);

    if(!this.data.isdelete && this.data.isadvitise && !this.data.isbanner && !this.data.islogout){
      console.log("patchvalues");
      console.log(this.data);
      // this.advitiseForm.patchValue({
      //   CategoryName:this.data.product.CategoryName,
      //   subcategoryName:this.data.product.subcategoryName,

      // })
      this.advcategories=[this.data.product];
      // this.categories = [];
      // this.categories.push({

      //   categoryName : this.data.product.CategoryID,
      // })
      // console.log(this.categories);
    }
  }
  setSubCat1(category) {
    console.log(category)
    let index;
    index = this.categories.findIndex(val => {
      return val.categoryName == category;
    })
    if (index !== -1) {
      this.subCategories = this.categories[index].subCategorys
    }
    if (index !== -1) {
      this.advitiseForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
      this.advitiseForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
    }
  }

  setSubCatId1(subCat) {
    console.log(subCat)
    let index;
    index = this.subCategories.findIndex(val => {
      return val.subCategoryName == subCat;
    })
    if (index !== -1) {
      this.advitiseForm.patchValue({ subCategoryID: this.subCategories[index].subCategoryID
      })
    }
  }

  setSubCat(category) {
    console.log(category)
    let index;
    index = this.categories.findIndex(val => {
      return val.categoryName == category;
    })
    if (index !== -1) {
      this.subCategories = this.categories[index].subCategorys
    }
    if (index !== -1) {
      this.advitiseForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
      this.advitiseForm.patchValue({ CategoryID: this.categories[index].categoryID
        
      })
    }
  }

  setSubCatId(subCat) {
    console.log(subCat)
    let index;
    index = this.subCategories.findIndex(val => {
      return val.subCategoryName == subCat;
    })
    if (index !== -1) {
      this.advitiseForm.patchValue({ subCategoryID: this.subCategories[index].subCategoryID
      })
    }
  }
  // get pInfoArray() {
  //   return <FormArray>this.advitiseForm.get('productInfo');
  
  // }
  getpathchvaluess(){

    if(!this.data.isdelete && !this.data.isadvitise && this.data.isbanner && !this.data.islogout){
      console.log("patchvalues");
      console.log(this.data);
      this.advitiseForm.patchValue({
        CategoryName:this.data.product.CategoryName,
        subcategoryName:this.data.product.subcategoryName,
      })
      this.categories = [];
      this.categories.push({
        categoryName : this.data.product.CategoryID,
      })
      console.log(this.categories);
    }

  }


  public onCancel = () => {
    this.dialogRef.close(false);
  }
  onSubmit() {
    this.dialogRef.close(true)
  }
  ondelete() {
    this.dialogRef.close(false)
  }



  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  //get Products
  getProductsData() {
    //let payLoad = { categoryID: this.user.categoryID };
    let obj = {
      type: "All"
    }
    //let token = localStorage.getItem('token')
    this.productsSubcribe = this.productService.getProduct(obj).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (this.serverResponse.response == 3) {

        this.produtList = this.serverResponse.DashBoardData[0].baners
        this.advitiseimage = this.serverResponse.DashBoardData[0].advertisements
      }
    }, (err) => {
      console.log(err)
    })
  }

  filePro(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.bannerForm.get('image').setValue(file);
        this.editFile = false;
        this.removeUpload = true;
        this.fileNames = file.name
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  

  fileProo(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.advitiseForm.get('image').setValue(file);
        this.editFile = false;
        this.removeUpload = true;
        this.fileNamess = file.name
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  advitise() {

    let userInfo = JSON.parse(localStorage.getItem('user'));
    console.log(userInfo)
    const Advitisements = new FormData();
    console.log(this.advitiseForm.value)

    let payLoad = { ...this.advitiseForm.value };
    let phno = userInfo.PhoneNumber.substring(3)
    payLoad.PhoneNumber = phno;
    delete payLoad.categoryName;
    Advitisements.append("image", this.advitiseForm.get("image").value);
    delete payLoad.image;


    console.log(payLoad)
    Advitisements.append("dashboardInfo", JSON.stringify(payLoad));
    let token = localStorage.getItem('token');
    this.productService.Advitisementsinsert(Advitisements, token).subscribe((res) => {

      console.log("Res", res)
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.getProductsData();
        //this.router.navigateByUrl('/home')
      } else {
        this.openSnackBar(res.message, "");
      }
    }, (err) => {
      console.log("error", err)
    })
  }
}
