import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar,MatDialogConfig, MatDialog } from '@angular/material';
import { AddressDailogComponent } from '../address-dailog/address-dailog.component';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-select-delivery',
  templateUrl: './select-delivery.component.html',
  styleUrls: ['./select-delivery.component.css']
})
export class SelectDeliveryComponent implements OnInit {
  totalItems:number;
  totalCost:number;
  totalMrp:number;
  totalSavings:number;
  cartObj:any;
  mobileNum:any;
  cartResponse:any;
  cartItems:any;
  cardHeader:string;
  vedasAddress:any;
  userAddress:Array<any> = []
  constructor(private productService:ProductsService, private router:Router,private snackBar:MatSnackBar,
    private dialog: MatDialog, private fb:FormBuilder) { }

  ngOnInit() {
    this.cartObj = localStorage.getItem('cartDetails')
    this.mobileNum =localStorage.getItem('mobileNumber');
    this.cardHeader ="Select Type of delivery"
    this.getCartsData()
    this.getSelfPickupPoint()
    this.getAddress()
  }
//Message Alerts showing
openSnackBar(message: string, action: string) {
  this.snackBar.open(message, action, {
     duration: 2000,
  });
} 
  getCartsData(){
    this.totalItems = 0;
    this.totalCost =0;
    this.totalMrp =0;
    this.totalSavings =0;
    this.cartObj = localStorage.getItem('cartDetails')
    this.mobileNum =localStorage.getItem('mobileNumber')
    if(this.mobileNum && this.cartObj){
      this.productService.cartData(this.mobileNum).subscribe((res)=>{
        this.cartResponse = res;
        if(this.cartResponse[0].response == '3'){
          this.cartItems = this.cartResponse[0].cartList;
          this.cartItems.forEach(element => {
            console.log(element.quantity)
            this.totalItems += parseFloat(element.quantity);
            this.totalCost += parseFloat(element.vmartPrice) * parseFloat(element.quantity);
            this.totalMrp += parseFloat(element.mrpPrice) * parseFloat(element.quantity);
            this.totalSavings += (parseFloat(element.mrpPrice) * parseFloat(element.quantity)) - (parseFloat(element.vmartPrice) * parseFloat(element.quantity));
          });
          console.log("elementsCount",this.totalItems);
           this.productService.totalCartItems.next(this.totalItems)
        }else{
          this.openSnackBar(this.cartResponse[0].message,"")
        }
      },(err:HttpErrorResponse)=>{
        if(err.error instanceof Error){
          console.log(err.error.message)
        }else{
          console.log(err.message)
         }
      })
    }else{
      this.openSnackBar("Login with your mobile number to continue shoping","")
      this.router.navigateByUrl('/home')
    }
  }
  getAddress(){
if(this.mobileNum && this.cartObj){
  this.productService.fetchUserAddress(this.mobileNum).subscribe((getAddress)=>{
    if(getAddress[0].response === "3"){
this.userAddress = getAddress[0].addressList
console.log(this.userAddress)
    }
  },(err)=>{
    console.log(err)
  })
}
  }
  getSelfPickupPoint(){
    this.productService.fetchVedasmartAddress().subscribe((address)=>{
      if(address[0].response == 3){
        this.vedasAddress = address[0].addressList
      }else{
        this.openSnackBar(address[0].message,"")
      }
    },(err)=>{console.log(err)})
  }
  openDialog(type,address){
    const dialogConfig = new MatDialogConfig();
const dailogRef=this.dialog.open(AddressDailogComponent,{ 
  height:"600px",
  width:"400px",
  hasBackdrop: false,
  autoFocus :true,
  data: { operationType: type,
        selectedAddress:address}
})
    dailogRef.afterClosed().subscribe(val=>{
      if(val && val[0] && val[0].response === "3"){
        this.getAddress()
      }else{
        console.log("reson","BackDrop Click")
      }
    })
  }
  deleteAddress(id){
    this.productService.deleteUserAddress(id).subscribe(res=>{
      if(res[0].response === "3"){
        this.openSnackBar("User Address Deleted","")
        this.getAddress()
      }
    },(err)=>{
      console.log(err)
    })
  }
}
 