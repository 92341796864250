import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProductsService } from 'src/app/services/products.service';
import { Router } from '@angular/router';
import { MatIcon,MatDialog } from '@angular/material'
import { MatSnackBar } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loginForm: FormGroup;
  loginResponse: any;
  otpResponse: any;
  gotNumber: Boolean = false;
  isLogin: boolean=false;
  issignIn: boolean;
  mblNum: any;
  cartObj: any;
  cartNum: string;
  totalItems: Number = 0;
  user : any;
  constructor(
    private modalService: NgbModal, 
    private fb: FormBuilder, 
    private product: ProductsService, 
    private router: Router,
    private snackBar:MatSnackBar,
    private dialog :MatDialog)  {
      
    this.product.totalCartItems.subscribe(val => {
      this.totalItems = val
    }, err => {
      console.log(err)
    })
    this.product.isUserLoggedIn.subscribe(val => {
      if(val == true){
      this.isLogin = true;
      this.issignIn = true;
      }
    })
    
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user")) || null;
    if (this.user && this.user.userID) {
      this.isLogin = true;
      this.product.isUserLoggedIn.next(true);
    } else {
      this.isLogin = false;
      this.product.isUserLoggedIn.next(false);
    }
    this.cartObj = localStorage.getItem('cartDetails');
    this.mblNum = localStorage.getItem('mobileNumber');
    this.cartNum = JSON.parse(this.cartObj);
   
    // if (this.mblNum && this.cartNum) {
    //   this.isLogin = true;
    //   this.issignIn = true;
    //   //this.product.isUserLoggedIn.next(true);
    // }
  }
  openModel(content) {
    this.modalService.open(content, { centered: true, size: "sm" });
  }
  getCategoriesList(){
    let obj ={
      type: "All"
    }
    let token = localStorage.getItem('token');
    this.product.fetchCategory(obj, token).subscribe((list) => {
      if (list.response == 3) {
        this.product.categoriesList = list.CategoryInfo
      }
    }, err => {
      console.log(err)
    })
  }
  sendMobileNumber(data) {
    console.log("data", data)
    let number = "+91" + data;
    this.product.sendNumber(number).subscribe((posRes) => {
      this.loginResponse = posRes;
      localStorage.setItem('mobileNumber', number)
      if (this.loginResponse[0].response == "3") {
        this.gotNumber = true;
        this.loginForm.patchValue({
          mobileNumber: number
        })
      }
      console.log("Login Status", this.loginResponse)
    }, (err) => { console.log(err) })
  }
  logout(products) {

    const dialogRef = this.dialog.open(DialogComponent, {
      height: '200px',
      width: '250px',
      disableClose: true,
      data:{ product:products,isbanner:false,isadvitise:false,isdelete:false,islogout:true}
    });
    dialogRef.afterClosed().subscribe((res) => {
      if(res == true){
      this.product.isUserLoggedIn.next(true);
      this.isLogin=false;
      localStorage.clear();
    //  window.location.reload()
      this.router.navigateByUrl('/home')
     
      }else{
        this.product.isUserLoggedIn.next(false);
      }
    })
    // localStorage.removeItem('cartDetails');
    // localStorage.removeItem('mobileNumber'); 
    // this.product.isUserLoggedIn.next(false);
    // this.isLogin=false;
    // localStorage.clear();
    // this.router.navigateByUrl('/home')
   
  }

  goorders(){
    this.router.navigateByUrl('/orders')
  }
  goorder(){
    this.router.navigateByUrl('/cart')
  }
  gocart(){
  if(this.isLogin){
    this.router.navigateByUrl('/cart')
  
  }else{
    this.openSnackBar("please login ","")
  }

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

  sendOtp() {
    console.log("login", this.loginForm.value)
    this.product.verifyOtp(this.loginForm.value).subscribe((res) => {
      this.otpResponse = res;
      if (this.otpResponse[0].response == "3") {
        alert(this.otpResponse[0].message)
        localStorage.setItem('cartDetails', JSON.stringify(this.otpResponse[0]))
        this.isLogin = true;
        this.issignIn = true;
        this.modalService.dismissAll();
        this.product.isUserLoggedIn.next(true);
      } else {
        alert(this.otpResponse[0].message)
      }
      console.log("otp", this.otpResponse)
    }, (err) => { console.log(err) })
  }

}
