import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  loading: boolean=false;

  constructor(private formBuilder: FormBuilder, private services: ProductsService,
    private router: Router,private snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        Name: new FormControl('', [Validators.required]),
        userID: new FormControl('', [Validators.required,
          Validators.email, Validators.pattern(
            '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,63}$')
          ]),
       
        PhoneNumber: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        BankName: new FormControl('', [Validators.required]),
        AccountNumber: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        IFSCCode: new FormControl('', [Validators.required,Validators.pattern('^[A-Z]{4}0[0-9]{6}$')]),
        AccountName: new FormControl('', [Validators.required]),
        Password: new FormControl(
          '',
          [
            Validators.required,
            // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$')

          ]
        ),

      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  numericOnly(event) {    
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onSubmit(): void {
    this.submitted = true;

    // if (this.form.invalid) {
    //   return;
    // }

    
    console.log("successfully signup")
    if (this.submitted) {
      this.loading = true;
      
      console.log(this.form.value)
      console.log(JSON.stringify(this.form.value, null, 2));
      this.services.signup(this.form.value).subscribe((result) => {
       console.log(result)
       this.services.isUserLoggedIn.next(true);
       this.openSnackBar("thank you for signup", "");
          this.router.navigateByUrl('/home')
        // if (result.response == 3) {
        //   console.log(result);
        //   this.router.navigateByUrl('/home')
          
        // }
       
      })
    } 
    // else {
    //   this.openSnackBar("", "");
    //   this.router.navigateByUrl('/home')
    // }
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }


}
