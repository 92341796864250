import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from "@angular/material";
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material';
import { SubcategoryUpdateComponent } from '../subcategory-update/subcategory-update.component';

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.css']
})
export class SingleProductComponent implements OnInit {
  sub: any;
  id: number;
  serverResponse: any;
  productDetails: any = [];
  selectedProduct: any;
  itemQuntity: string;
  cartResponse: any;
  mobileNum: any;
  cartObj: any;
  cartNum: any;
  isSelected: boolean = false;
  itemCount: number = 0;
  updateResponse: any;
  cartList: any = [];
  matchedProduct: any;
  totalItems: number;
  baseurl: string;
  isLoading: boolean;
  constructor(private products: ProductsService, private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,public dialog :MatDialog) {
    this.products.isUserLoggedIn.subscribe(value => {
      console.log("Boolean", value)
      this.getCartData();

    });
  }

  ngOnInit() {
    this.baseurl="https://liveapi-vmart.softexer.com";
    this.totalItems = 0;
    this.itemQuntity = "1";
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
     this.getsubcatinfo();
     
    })
    
  }
  
  getsubcatinfo(){
    let obj={
      type:this.id
    }
   // let token = localStorage.getItem('token')
    this.products.getProduct(obj).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(posRes);
      if (this.serverResponse.response == 3) {
        this.productDetails = this.serverResponse.CategoryInfo
        this.selectedProduct = this.productDetails[0].subCategorys;
        console.log(this.selectedProduct)
        this.selectedProduct.forEach(val => {
          val.isSelected = false;
          val.numOfItems = 1;
        })
        console.log(this.productDetails);
        // let index;
        // index = this.productDetails.findIndex(val => {
        //   return val.subCategoryID == this.id;
        // })
        // if (index !== -1) {
        //   this.selectedProduct = this.productDetails[index];
        // }
      } else {
        this.openSnackBar("Login with Your mobile Number ", "")
        alert(this.serverResponse.message)
      }
    },
     (err) => {
      console.log(err)
    })
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  //Get Items from cart and check selected product is in cart or not
  getCartData() {
    this.mobileNum = localStorage.getItem('mobileNumber');
    this.cartObj = localStorage.getItem('cartDetails');
    if (this.mobileNum && this.cartObj) {
      this.products.cartData(this.mobileNum).subscribe((res) => {
        this.cartResponse = res;
        if (this.cartResponse[0].response == '3') {
          this.cartList = this.cartResponse[0].cartList;
          this.cartList.forEach(count => {
            this.totalItems += parseFloat(count.quantity)
          })
          this.products.totalCartItems.next(this.totalItems)
          let cartIndex;
          cartIndex = this.cartList.findIndex(val => {
            return val.subCategoryID == this.id;
          })
          if (cartIndex !== -1) {
            this.matchedProduct = this.cartList[cartIndex];
            this.itemCount = this.matchedProduct.quantity;
            if (this.itemCount >= 1) {
              this.isSelected = true;
            }
          }
        } else {
          this.openSnackBar(this.cartResponse[0].message, "")
        }
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log(err.error.message)
        } else {
          console.log(err.message)
        }
      })
    } else {
      this.cartList = []
      this.isSelected = false;
      this.totalItems = 0;
      this.products.totalCartItems.next(this.totalItems)
    }
  }
  //Add one item to Cart 
  addToCart() {
    this.mobileNum = localStorage.getItem('mobileNumber');
    this.cartObj = localStorage.getItem('cartDetails');
    let timeStamp = Math.round((new Date()).getTime() / 1000);
    if (this.mobileNum && this.cartObj) {
      this.cartNum = JSON.parse(this.cartObj);
      let details = {                                  
        cartId:this.cartNum.cartId,
        productId:this.selectedProduct.productId,
        mobileNumber:this.mobileNum,
        itemName:this.selectedProduct.itemName,
        mrpPrice:this.selectedProduct.subCategorys[0].mrp,
        vmartPrice:this.selectedProduct.subCategorys[0].vmartPrice,
        quantity:"1",
        netWeight:this.selectedProduct.subCategorys[0].quantity,
        timeStamp:timeStamp,
        url:this.selectedProduct.subCategorys[0].url,
        merchantId:this.selectedProduct.subCategorys[0].merchantId,
        availability:this.selectedProduct.subCategorys[0].availability,
        gstNumber:this.selectedProduct.subCategorys[0].gstNumber,
        cmerchantId:this.selectedProduct.subCategorys[0].cmerchantId,
        shippingPrice:this.selectedProduct.subCategorys[0].shippingPrice
      }
      this.products.addToCart(details).subscribe((cartRes) => {
        this.cartResponse = cartRes;
        if (this.cartResponse[0].response == "3") {
          this.openSnackBar(this.cartResponse[0].message, "Thank u")
          this.isSelected = true;
          this.itemCount = 1;
          this.totalItems += 1;
          this.products.totalCartItems.next(this.totalItems)
        } else {
          this.openSnackBar(this.cartResponse[0].message, "")
        }
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log(err.error.message)
        } else {
          console.log(err.message)
        }
      })
      console.log("cartData", details)
    } else {
      this.openSnackBar("Login with Your mobile Number to add items into cart", "")
    }
  }

  //increment one item to cart
  addOneToCart() {
    let timeStamp = Math.round((new Date()).getTime() / 1000);
    if (this.mobileNum && this.cartObj) {
      this.cartNum = JSON.parse(this.cartObj);
      let details = {
        productId: this.selectedProduct.productId,
        cartId: this.cartNum.cartId,
        itemName: this.selectedProduct.itemName,
        mrpPrice: this.selectedProduct.subCategorys[0].mrp,
        vmartPrice: this.selectedProduct.subCategorys[0].vmartPrice,
        quantity: 1 + this.itemCount,
        mobileNumber: this.mobileNum,
        netWeight: this.selectedProduct.subCategorys[0].quantity,
        timeStamp: timeStamp
      }
      if (this.itemCount <= 14) {
        this.products.updateCart(details).subscribe((cartRes) => {
          this.updateResponse = cartRes;
          if (this.updateResponse[0].response == "3") {
            this.itemCount++
            this.totalItems++
            this.products.totalCartItems.next(this.totalItems)
            this.openSnackBar(this.updateResponse[0].message, "Added")
          }
          console.log("CartResponse", this.cartResponse)
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log(err.error.message)
          } else {
            console.log(err.message)
          }
        })
      } else {
        this.openSnackBar("Cart limit 15 for one item", "")
      }
    } else {
      this.openSnackBar("Login With Mobile Number", "")
    }
  }
  //Remove one item from Cart
  removeOneFromCart() {
    let timeStamp = Math.round((new Date()).getTime() / 1000);
    if (this.mobileNum && this.cartObj) {
      this.cartNum = JSON.parse(this.cartObj);
      let details = {
        productId: this.selectedProduct.productId,
        cartId: this.cartNum.cartId,
        itemName: this.selectedProduct.itemName,
        mrpPrice: this.selectedProduct.subCategorys[0].mrp,
        vmartPrice: this.selectedProduct.subCategorys[0].vmartPrice,
        quantity: this.itemCount - 1,
        mobileNumber: this.mobileNum,
        netWeight: this.selectedProduct.subCategorys[0].quantity,
        timeStamp: timeStamp
      }
      this.products.updateCart(details).subscribe((cartRes) => {
        this.updateResponse = cartRes;
        if (this.updateResponse[0].response == "3") {
          if (this.itemCount == 1) {
            this.isSelected = false;
            this.removeItem();
          } else {
            this.itemCount--
            this.openSnackBar(this.updateResponse[0].message, "Removed")
            this.totalItems--
            this.products.totalCartItems.next(this.totalItems)
          }
        }
        console.log("CartResponse", this.cartResponse)
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log(err.error.message)
        } else {
          console.log(err.message)
        }
      })
      console.log("cartData", details)
    } else {
      this.openSnackBar("Login with your mobile number before adding items", "")
    }
  }
  //Remove whole item from cart
  removeItem() {
    let removeDetails = {
      productId: this.selectedProduct.productId,
      mobileNumber: this.mobileNum,
      netWeight: this.selectedProduct.subCategorys[0].quantity
    }
    this.products.removeItem(removeDetails).subscribe((dbRes) => {
      if (dbRes[0].response == "3") {
        this.openSnackBar(dbRes[0].message, "")
        this.totalItems--
        this.products.totalCartItems.next(this.totalItems)
      } else {
        this.openSnackBar(dbRes[0].message, "")
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log(err.error.message)
      } else {
        console.log(err.message)
      }
    })
  }
  editingsub(product){

    const dailogRef = this.dialog.open(SubcategoryUpdateComponent, {
      height: '500px',
      width: '500px',
      disableClose: true,
      data: { products: product, categoryList: this.productDetails }
    });
    dailogRef.afterClosed().subscribe((res) => {
      console.log(res);
      if (res) {
        this.getsubcatinfo();
      }
    });
  }

}
