import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { SingleProductComponent } from './components/single-product/single-product.component';
import { CartComponent } from './components/cart/cart.component';
import { SelectDeliveryComponent } from './components/select-delivery/select-delivery.component';
import { LoginComponent } from './components/login/login.component';
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { TimeSlotsComponent } from './components/time-slots/time-slots.component';
import { GroceryComponent } from './components/grocery/grocery.component';
import { SignupComponent } from './components/signup/signup.component';
import { TermsComponent } from './components/terms/terms.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ProductsComponent } from './components/products/products.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { RefundComponent } from './components/refund/refund.component';
import { FrequentlyComponent } from './components/frequently/frequently.component';
import { OrdersComponent } from './components/orders/orders.component';
import { SellerordersComponent } from './components/sellerorders/sellerorders.component';
import { VmartaddressComponent } from './components/vmartaddress/vmartaddress.component';

const routes: Routes = [
  {path:"home",component:HomeComponent},
  {path:"product/:id",component:SingleProductComponent},
  {path:"product",component:SingleProductComponent},
  {path:'cart',component:CartComponent},
  {path:'delivery',component:SelectDeliveryComponent},
  {path:'login',component:LoginComponent},
  {path:'admin',component:AdminPanelComponent},
  {path:'timeSlots',component:TimeSlotsComponent},
  {path:'signup',component:SignupComponent},
  {path:'grocery',component:GroceryComponent},
  {path:'terms-and-conditions',component:TermsComponent},
  {path:'aboutUs',component:AboutUsComponent},
  {path:'privacy',component:PrivacypolicyComponent},
  {path:'refund',component:RefundComponent},
  {path:'frequent',component:FrequentlyComponent},
  {path:'contactus',component:ContactUsComponent},
  {path:'orders',component:SellerordersComponent},
  {path:'orderss/:id',component:OrdersComponent},
  {path:'adress',component:VmartaddressComponent},
  {path:"products/:id",component:ProductsComponent},
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
