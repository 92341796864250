import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { SelectDeliveryComponent } from '../select-delivery/select-delivery.component';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-address-dailog',
  templateUrl: './address-dailog.component.html',
  styleUrls: ['./address-dailog.component.css']
})
export class AddressDailogComponent implements OnInit {
  addressForm:FormGroup
  addressRes:any;
  mobileNum:any;
  selectedAddress:any;
  constructor(private fb:FormBuilder,private dialogRef: MatDialogRef<SelectDeliveryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private productService:ProductsService,public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.mobileNum =localStorage.getItem('mobileNumber')
    this.addressForm = this.fb.group({
      name:[""],
      pinCode:[""],
      address:[""],
      state:[""],
      mobile:[""],
      city:[""],
      area:[""],
      landmark:[""],
      userId:[""],
      favourite:[false],
      latitude:[""],
      longitude:[""],
      timeStamp:[""]
    })
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("Position",position);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
    this.updateAddress()
  }
 //message alerts showing
 openSnackBar(message: string, action: string) {
  this.snackBar.open(message, action, {
     duration: 2000,
  });
}
updateAddress(){
 if(this.data.operationType === "update"){
  this.selectedAddress = this.data.selectedAddress;
  this.addressForm.patchValue({
    userId: this.mobileNum,
    name:this.selectedAddress.name,
    pinCode:this.selectedAddress.pinCode,
    address:this.selectedAddress.address,
    state:this.selectedAddress.state,
    mobile:this.selectedAddress.mobile,
    city:this.selectedAddress.city,
    area:this.selectedAddress.area,
    landmark:this.selectedAddress.landmark,
    Latitude:"12.956113500000000",
    Longitude:"77.593063200000000",
  })
 }
}
closeModal(){
  this.dialogRef.close()
}
  sendAddress(){
    let timeStamp = Math.round((new Date()).getTime() / 1000);
    if(this.data.operationType === "creat" && this.mobileNum){
      this.addressForm.patchValue({
        userId: this.mobileNum,
        timeStamp: timeStamp,
        latitude:78.022840,
        longitude:27.207620
      })
      console.log(this.addressForm.value)
      this.productService.insertUserAddress(this.addressForm.value).subscribe((res)=>{
        this.addressRes = res;
        console.log("Response",this.addressRes)
        if(this.addressRes[0].response == "3"){
          this.dialogRef.close(this.addressRes);
          console.log("addressRes",this.addressRes[0].message)
        }
      })
    }else if(this.data.operationType === "update" && this.mobileNum){
      this.addressForm.patchValue({
        timestamp:timeStamp
      })
      const payLoad = {...this.addressForm.value}
      payLoad.addressId = this.selectedAddress.addressId
      console.log("payLoad",payLoad)
      this.productService.updateUserAddress(payLoad).subscribe((res)=>{
        this.addressRes = res;
        console.log("Response",this.addressRes)
        if(this.addressRes[0].response == "3"){
          this.dialogRef.close(this.addressRes);

          console.log("addressRes",this.addressRes[0].message)
        }
      })
    }

  }
}
