import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-sellerorders',
  templateUrl: './sellerorders.component.html',
  styleUrls: ['./sellerorders.component.css']
})
export class SellerordersComponent implements OnInit {
  productsSubcribe: any;
  serverResponse: any;
  productsList: any = [];
  orders:any=[];
  totalItems: number;
  totalCost: number;
  totalMrp: number;
  totalSavings: number;
  isItemsPresent: Boolean;
  cartResponse: any;
  cartItems: any = [];
  Eid: any;
  baseurls: any;
  sub: any;
  id: any;

  constructor(private services:ProductsService,private snackBar:MatSnackBar,private router:Router,private activatedRoute: ActivatedRoute
   ) {

    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
     
    })
    }

  ngOnInit() {
    this.baseurls="https://liveapi-vmart.softexer.com/"
    //this.getCartsData()
    this.getordersData()
   
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  getordersData(){
    let userInfo = JSON.stringify(localStorage.getItem('user'));
    let obj={
      type:"All"
    }
    let token = localStorage.getItem('token');
    this.productsSubcribe = this.services.getorders(obj,token).subscribe((posRes) => {
      console.log(userInfo);
      this.serverResponse = posRes;
      console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
       
        this.productsList = this.serverResponse.Info[0].Products;
        this.orders=this.serverResponse.Info;
        
      }
    
  })
}


ordersview(orderInfo){
  this.router.navigate(["/orderss", btoa(JSON.stringify(orderInfo))], {
    queryParams: { isEncpt: "y" },
  });
//this.router.navigateByUrl('/orderss')

}

//   getCartsData() {
//     this.totalItems = 0;
//     this.totalCost = 0;
//     this.totalMrp = 0;
//     this.totalSavings = 0;
   
//     let userInfo = JSON.parse(localStorage.getItem('user'));
//     if (userInfo) {
//       this.services.cartData(userInfo).subscribe((res) => {
//         this.cartResponse = res;
//         if (this.cartResponse.response == 3) {
//           this.cartItems = this.cartResponse.cartList;
//           this.cartItems.forEach(element => {
//             console.log(element.quantity)
//             this.totalItems += parseFloat(element.quantity);
//             this.totalCost += parseFloat(element.vmartPrice) * parseFloat(element.quantity);
//             this.totalMrp += parseFloat(element.mrpPrice) * parseFloat(element.quantity);
//             this.totalSavings += (parseFloat(element.mrpPrice) * parseFloat(element.quantity)) - (parseFloat(element.vmartPrice) * parseFloat(element.quantity));
//           });
//           console.log("elementsCount", this.totalItems);
//           this.services.totalCartItems.next(this.totalItems)
//         } else {
//           this.openSnackBar(this.cartResponse.message, "")
//         }
//       }, (err: HttpErrorResponse) => {
//         if (err.error instanceof Error) {
//           console.log(err.error.message)
//         } else {
//           console.log(err.message)
//         }
//       })
//     } else {
//       this.openSnackBar("please Add products in your cart", "")
//       this.router.navigateByUrl('/home')
//     }

//   }
 }
