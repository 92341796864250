import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { ProductsComponent } from '../products/products.component';
@Component({
  selector: 'app-dealsdialog',
  templateUrl: './dealsdialog.component.html',
  styleUrls: ['./dealsdialog.component.css']
})
export class DealsdialogComponent implements OnInit {
  baseurls: string;
  addtodaydealsForm:FormGroup;
  editFile: boolean;
  removeUpload: boolean;
  fileName: any;
  
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef, private productService: ProductsService,
    private snackBar: MatSnackBar,private router:Router,private activatedRoute: ActivatedRoute,
    private dialog: MatDialog, public dialogRef: MatDialogRef<ProductsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {
    this.baseurls="https://liveapi-vmart.softexer.com/"
    this.addtodaydealsForm = this.fb.group({
      SubCategoryProfilePic: [''],
      ProductName: new FormControl('', [Validators.required]),
      Brand: new FormControl('', [Validators.required]),
      subCategoryID: [""],
      CategoryID: [""],
      description:  new FormControl('', [Validators.required]),
      quantity: new FormControl('', [Validators.required]),
      MRP_Price: new FormControl('', [Validators.required]),
      VMART_Price:new FormControl('', [Validators.required]),
      Netweight:"",
      categoryName: [""],
      subCategoryName: [""]
    })
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  fileProgress5(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.addtodaydealsForm.get('SubCategoryProfilePic').setValue(file);
        this.editFile = false;
        this.removeUpload = true;
        this.fileName = file.name
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  adddeals(products){
    console.log(products)
  this.addtodaydealsForm.patchValue({
    categoryName:products.categoryName,
    subCategoryName:products.subcategory.subCategoryName,
  })

  }

}
