import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  sub: any;
  id: any;
  baseurls: string;
  productsSubcribe: any;
  serverResponse: any;
  queryStr: any;
  routeSub: any;
  idInfo : string ="";
  productsList: any = [];
  constructor(private services:ProductsService,private snackBar:MatSnackBar,private router:Router,private activatedRoute: ActivatedRoute
    ) {
 
     this.sub = this.activatedRoute.params.subscribe(params => {
       this.id = params['id'];
      
     })
     }

  ngOnInit() {
    this.baseurls="https://liveapi-vmart.softexer.com/"
    //this.getCartsData()
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryStr = { ...params };
    });
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      //log the entire params object
      this.id = params["id"];
     
     
    });
    if (this.queryStr.params.isEncpt == "y") {
      this.id = atob(this.id);
     
      console.log(this.id);
    } else {
      this.id = atob(this.id);
      console.log(this.id);
    }
    this.productsList = JSON.parse(this.id);
    console.log(this.productsList)
    //this.getordersData()
   
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  orderapprove(){
    let obj={
      PhoneNumber:this.productsList.PhoneNumber,
      orderId:this.productsList.orderId,
      orderstatus:"Confirmed"
      }
      let token = localStorage.getItem('token');
      this.productsSubcribe = this.services.getordersapprove(obj,token).subscribe((posRes) => {
        this.serverResponse = posRes;
        console.log(this.serverResponse);
        if (this.serverResponse.response == 3) {
          console.log(this.serverResponse);
          this.openSnackBar(posRes.message, "");
          this.router.navigateByUrl('/orders');
        }else{
          this.openSnackBar(posRes.message, "");
          this.router.navigateByUrl('/orders');
        }
      },(err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log(err.error.message)
        } else {
          console.log(err.message)
        }
      })
  }

  
  orderreject(){
    let obj={
      PhoneNumber:this.productsList.PhoneNumber,
      orderId:this.productsList.orderId,
      orderstatus:"Rejected"
      }
      let token = localStorage.getItem('token');
      this.productsSubcribe = this.services.getordersapprove(obj,token).subscribe((posRes) => {
        this.serverResponse = posRes;
        console.log(this.serverResponse);
        if (this.serverResponse.response == 3) {
  
          console.log(this.serverResponse);
          this.openSnackBar(posRes.message, "order Status is Updated");
          this.router.navigateByUrl('/orders');
        }else{
          this.openSnackBar(posRes.message, "");
          this.router.navigateByUrl('/orders');
        }
  
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log(err.error.message)
        } else {
          console.log(err.message)
        }
      })
  
  }

  getordersData(){
    let userInfo = JSON.stringify(localStorage.getItem('user'));
    let obj={
      type:"All"
    }
    let token = localStorage.getItem('token');
    this.productsSubcribe = this.services.getorders(obj,token).subscribe((posRes) => {
      console.log(userInfo);
      this.serverResponse = posRes;
      console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
       
        this.productsList = this.serverResponse.Info[0].Products;
       
        
      }
    
  })
}

}
