import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog,MatSnackBar } from '@angular/material';
import { UpdateproductComponent } from '../updateproduct/updateproduct.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  baseurl: string;
  totalItems: number;
  productsSubcribe: any;
  serverResponse: any;
  productsList: any = [];
  itemQuntity: string;
  sub: any;
  id: number;
  productDetails: any = [];
  selectedProduct: any;
  counter: number = 0;
  subCategoryid: any;
  cartResponse: any;
  isLoading: boolean;
  constructor(private products: ProductsService, private activatedRoute: ActivatedRoute,
    private dialog:MatDialog,private snackBar:MatSnackBar) {

      this.sub = this.activatedRoute.params.subscribe(params => {
        this.id = params['id'];
       
      })
     }
  increment() {
    this.counter++;
  }
  decrement() {
    this.counter--;
  }
  ngOnInit() {
    this.baseurl = "https://liveapi-vmart.softexer.com"
    this.totalItems = 0;
    this.itemQuntity = "1";
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getsubcatinfo();
    })
  }
  getsubcatinfo() {

    let obj = {
      type: this.id
    }
    console.log(this.id);
    let token = localStorage.getItem('token')
    this.products.Productsdisplay(obj,token).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(posRes)
      if (this.serverResponse.response == 3) {
        this.productsList = this.serverResponse.Info
        
      } else {
        alert(this.serverResponse.message)
      }
    },
      (err) => {
        console.log(err ,"plese login the will be display")
      })
  }

  addToCart(products) {
   
    let details={
      
            ProductID: "PID@MD9RbShEF1669273396485",
            Brand: "simla",
            MRP_Price: 80,
            VMART_Price: 60,
            quantity: "2",
            description: "good apple healthy products",
            postDate: "1669273396485",
            ProductImage: "/images/productsimages/pidMD9RbShEF@r1.jpg",
            Netweight: [
                "1",
                "2",
                "5",
                "25",
                "50"
            ]
    }

    this.products.addToCart(details).subscribe((cartRes) => {
      this.cartResponse = cartRes;
      if (this.cartResponse[0].response == "3") {
       // this.openSnackBar(this.cartResponse[0].message, "Thank you")
        //productData.isSelected = true;
        this.totalItems += 1;
        this.products.totalCartItems.next(this.totalItems)
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log(err.error.message)
      } else {
        console.log(err.message)
      }
    })
    console.log("cartData")
  } 


  deletetodaydeals(products){

    console.log(products);
   
    const dialogRef = this.dialog.open(DialogComponent, {
      height: '200px',
      width: '250px',
      disableClose: true,
      data:{ product:products,isbanner:false,isadvitise:false,isdelete:true}
    });
    dialogRef.afterClosed().subscribe((res) => {
      let userInfo = JSON.parse(localStorage.getItem('user'));
      if (res == true) {
        console.log(products)
        this.isLoading = true;
        let obj = {
          type:products.ProductID,
        }
        console.log(obj)
        let token = localStorage.getItem('token');
        this.products.deleteproducts(obj,token).subscribe((dele) => {
    
          console.log("del", dele);
          if (dele.response === 3) {
            this.isLoading = false;
            this.openSnackBar(dele.message, "");
           this.getsubcatinfo()
           console.log("success", dele);
          } else {
            this.isLoading = false;
          }
        }
          , (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client Error ::", err.error)
            } else {
              console.log("Server Error ::", err.error)
            }
          })
         
      }
      else{
        this.getsubcatinfo()
  
      }
    })
  }
  updatetodaydeals(products){
    let dailogRef = this.dialog.open(UpdateproductComponent, {
      panelClass: "col-md-4",
      hasBackdrop: true,
      disableClose: true,
      data: products,
    });
    dailogRef.afterClosed().subscribe((res) => {
      console.log(res);
      if (res) {
        this.getsubcatinfo();
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}



