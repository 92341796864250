import { Component, OnInit } from '@angular/core';
import { WindowService } from 'src/app/services/window.service';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { HttpErrorResponse } from "@angular/common/http";
import { MatDialog, MatSnackBar } from "@angular/material";
import { ForgotComponent } from '../forgot/forgot.component';
const config = {
  apiKey: "AIzaSyDEoLpxVnUnZ3VURmH4BunYgV4MQ5X8gUI",
  authDomain: "vmart-app-1541756069125.firebaseapp.com",
  databaseURL: "https://vmart-app-1541756069125.firebaseio.com",
  projectId: "vmart-app-1541756069125",
  storageBucket: "vmart-app-1541756069125.appspot.com",
  messagingSenderId: "621056572403",
};

// import * as firebase from 'firebase';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

// export class PhoneNumber {
//   country: string;
//   area: string;
//   prefix: string;
//   line: string;

//   // format phone numbers as E.164
//   get e164() {
//     const num = this.country + this.area + this.prefix + this.line;
//     return `+${num}`;
//   }
// }


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  windowRef: any;
  phone: any;
  showLoginScreen : boolean =true;
  // phoneNumber = new PhoneNumber();
  verificationCode: string;
  user: any;
  otpResponse: any;
  submitted: any;
  loading: boolean;
  hide = true;

  constructor(private win: WindowService,
    private router: Router,
    private services: ProductsService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private dialog:MatDialog) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group(
      {
        userID: new FormControl('', [Validators.required,
        // Validators.email, 
        // Validators.pattern(
        //   '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,63}$')
        ]),
        Password: new FormControl(
          '',
          [
            Validators.required
          ]
        ),
      });
    // this.windowRef = this.win.windowRef;
    // firebase.initializeApp(config);
    // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   'recaptcha-container'
    // );

    // this.windowRef.recaptchaVerifier.render().then(widgetId => {
    //   this.windowRef.recaptchaWidgetId = widgetId;
    // });
  }

    //message alerts showing
    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 3000,
        panelClass: "red-snackbar",
      });
    }

  onSubmit() {

    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = true;
    console.log("successfully login")
    if (this.submitted) {
      this.loading = true;

      console.log(this.loginForm.value)
      console.log(JSON.stringify(this.loginForm.value, null, 2));
      this.services.login(this.loginForm.value).subscribe((result) => {
        console.log(result);
        let userinfo = JSON.stringify(result);
        let uinfo = JSON.parse(userinfo);
        if(uinfo.response == 3){
          this.services.isUserLoggedIn.next(true);
          localStorage.setItem('user', JSON.stringify(uinfo.Info));
          window.localStorage.setItem("token", uinfo.jsontoken);
          this.router.navigateByUrl('/home')
          this.showLoginScreen=false;
        }else{
          this.openSnackBar(uinfo.message,"")
        }
        
        // if (result.response == 3) {
        //   console.log(result);
        //   this.router.navigateByUrl('/home')

        // }

      },
      (err: HttpErrorResponse) => {
        this.openSnackBar(err.message, "");
        if (err.error instanceof Error) {
          console.warn("Client Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      })
    } else {
      this.router.navigateByUrl('/timeSlots')
    }
  }

 

  forgot(){
    const dialogRef = this.dialog.open(ForgotComponent, {
      height: '400px',
      width: '400px'
    })
  }

  // sendLoginCode() {
  //   const appVerifier = this.windowRef.recaptchaVerifier;
  //   const num = this.phoneNumber.e164;
  //   console.log(num);
  //   firebase
  //     .auth()
  //     .signInWithPhoneNumber(this.phone, appVerifier)
  //     .then(result => {
  //       this.windowRef.confirmationResult = result;
  //     })
  //     .catch(error => console.log('error', error));
  // }

  // verifyLoginCode() {

  //     this.windowRef.confirmationResult
  //       .confirm(this.verificationCode)
  //       .then(result => {
  //         this.user = result.user;
  //         console.log(result);
  //         this.services.isUserLoggedIn.next(true);
  //         this.router.navigateByUrl('/home')
  //       })
  //       .catch(error => console.log(error, 'Incorrect code entered?'));


  // }
}
