import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  loading: boolean=false;

  constructor(private formBuilder: FormBuilder, private services: ProductsService,
    private router: Router) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        name: new FormControl('', [Validators.required]),
        emailAddress: new FormControl('', [Validators.required]),
          Message: new FormControl('', [Validators.required]),
        userId: new FormControl('', [Validators.required]),
        merchantId:"LBun0qNKBP@1668589696267",
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    console.log("successfully contact us")
    if (this.submitted) {
      this.loading = true;
      
      console.log(this.form.value)
      console.log(JSON.stringify(this.form.value, null, 2));
      this.services.contactus(this.form.value).subscribe((result) => {
       console.log(result)
       this.services.isUserLoggedIn.next(true);
          this.router.navigateByUrl('/home')

      })
    } else {
      this.router.navigateByUrl('/timeSlots')
    }
  }
}
